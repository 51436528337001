import React from 'react'
//import Material Ui Icons
import CloseIcon from '@material-ui/icons/Close';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import SecurityIcon from '@material-ui/icons/Security';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
//import Router
 import {
    Link,
    NavLink
} from "react-router-dom";

const NewTicket = () => {


    return (
        <div className="NewTicketWrapper">
            <div className="NewTicketPanel ThemeLayer2 ThemeShadow ThemeBorder">
                <Link to="/dashboard/support"><CloseIcon className="BankAddAccountPanelClose"/></Link>
                <div className="NewTicketHeader">
                    <span className="ThemeText2">دپارتمان</span>
                    <ul>
                        <NavLink to="/dashboard/new-ticket/money" activeClassName="SupportSelect"><li className="ThemeBorder ">
                            <MonetizationOnIcon />
                            <span className="ThemeText2">پشتیبانی مالی</span>
                        </li></NavLink>
                        <NavLink to="/dashboard/new-ticket/support" activeClassName="SupportSelect"><li className="ThemeBorder ">
                            <SecurityIcon />
                            <span className="ThemeText2">پشتیبانی فنی</span>
                        </li></NavLink>
                        <NavLink to="/dashboard/new-ticket/authentication" activeClassName="SupportSelect"> <li className="ThemeBorder ">
                            <AccountBoxIcon />
                            <span className="ThemeText2">احراز هویت</span>
                        </li></NavLink>
                    </ul>
                </div>
                <div className="NewTicketPanelUlWrapper">
                    <ul>
                        <li>
                            <div className="NewTicketItemHeader"><span className="ThemeText2">عنوان</span></div>
                            <div className="NewTicketItemBody"><input type="text" placeholder="عنوان" className="ThemeBorder"/></div>
                        </li>
                        <li>
                            <div className="NewTicketItemHeader"><span className="ThemeText2">توضیحات</span></div>
                            <div className="NewTicketItemBody"><textarea placeholder="توضیحات" className="ThemeBorder" rows="7"></textarea></div>
                        </li>
                        <li>
                            <div className="NewTicketFileUpload ThemeBorder">
                                <CloudUploadIcon />
                                <span className="ThemeText2">برای آپلود تصویر ضمیمه کلیک کنید</span>
                            </div> 
                        </li>
                    </ul>
                </div>

                <div className="NewTicketPanelSubmit ThemeBorder">
                    <span>ارسال</span>
                </div>
            </div>
        </div>
    )
}

export default NewTicket
