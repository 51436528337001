import React , {useEffect} from 'react'
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import SidebarMobile from '../Sidebar/SidebarMobile';
import Dashboard from './Dashboard/Dashboard';
import Animation from '../Animation';

import Profile from './Profile/Profile'
import EditProfile from './Profile/EditProfile';
import BankAddAccount from './Profile/BankAddAccount';
import AccountVerifyStart from './Profile/AccountVerifyStart';
import AccountVerify from './Profile/AccountVerify';
import Support from '../Support/Support';
import NewTicket from '../Support/NewTicket';
import ShowTicket from '../Support/ShowTicket';
import ReplyTicket from '../Support/ReplyTicket';
import Security from '../Security/Security';
import LoginHistory from '../Security/LoginHistory';
import AuthenticationPhone from '../Security/AuthenticationPhone';
import AuthenticationApp from '../Security/AuthenticationApp';
import ChangePassword from '../Security/ChangePassword';
import ChangePasswordEmail from '../Security/ChangePasswordEmail';
import Orders from '../History/Orders';
import Transactions from '../History/Transactions';
import Deals from '../History/Deals';
import Withdraw from '../History/Withdraw';
import Deposit from '../History/Deposit';
import RefferalCode from '../InviteFriends/RefferalCode';
import RefferalList from '../InviteFriends/RefferalList';
import Purification from '../InviteFriends/Purification';
import Wallet from '../Wallet/Wallet';
import Financial from '../Financial/Financial';
import DepositRial from '../Financial/DepositRial';
//import Router
import {
    Switch,
    Route,
  } from "react-router-dom";




const MainContent = ({SidebarToggleMod,setTheme,ThemeMode,SidebarTogglerM,SidebarToggleModM,setLanguage,languageMode,SidebarToggler,loading}) => {
    
  React.useEffect(() => {
        
    setTimeout(
        () => loading (false), 
        3000
      );

})
  

    return (

      

        <React.Fragment>
         <Navbar setTheme={setTheme} ThemeMode={ThemeMode} setLanguage={setLanguage} languageMode={languageMode} SidebarToggler={SidebarToggler} SidebarTogglerM={SidebarTogglerM}/>
        <SidebarMobile setTheme={setTheme} ThemeMode={ThemeMode} SidebarTogglerM={SidebarTogglerM} SidebarToggleModM={SidebarToggleModM} />
        <Sidebar Loading={loading} SidebarToggler={SidebarToggler} SidebarToggleMod={SidebarToggleMod} />
        <div className={SidebarToggleMod === false ? 'MainContentWrapper' : 'MainContentWrapper'}>
           <Switch>
           
          <Route path="/dashboard/wallet">
            <Wallet Loading={loading}/>
          </Route>
          <Route path="/dashboard/animation">
            <Animation Loading={loading}/>
          </Route>
          <Route path="/dashboard" exact>
            <Dashboard Loading={loading} />
          </Route>
          <Route path="/dashboard/support">
            <Support Loading={loading}/>
          </Route>
          <Route path="/dashboard/profile-edit">
            <EditProfile Loading={loading}/>
          </Route>
          <Route path="/dashboard/profile">
            <Profile Loading={loading}/>
          </Route>
          <Route path="/dashboard/bank-edit">
            <EditProfile Loading={loading}/>
          </Route>
          <Route path="/dashboard/bank-add">
            <BankAddAccount Loading={loading}/>
          </Route>
          <Route path="/dashboard/account-verify-start">
            <AccountVerifyStart Loading={loading}/>
          </Route>
          <Route path="/dashboard/account-verification">
            <AccountVerify Loading={loading}/>
          </Route>
          
          <Route path="/dashboard/new-ticket">
            <NewTicket Loading={loading}/>
          </Route>
          <Route path="/dashboard/show-ticket">
            <ShowTicket Loading={loading}/>
          </Route>
          <Route path="/dashboard/reply-ticket">
            <ReplyTicket Loading={loading}/>
          </Route>
          <Route path="/dashboard/security">
            <Security Loading={loading}/>
          </Route>
          <Route path="/dashboard/security-status">
            <Security Loading={loading}/>
          </Route>
          <Route path="/dashboard/login-history">
            <LoginHistory Loading={loading}/>
          </Route>
          <Route path="/dashboard/authentication-phone">
            <AuthenticationPhone Loading={loading}/>
          </Route>
          <Route path="/dashboard/authentication-app">
            <AuthenticationApp Loading={loading}/>
          </Route>
          <Route path="/dashboard/change-password">
            <ChangePassword Loading={loading}/>
          </Route>
          <Route path="/dashboard/change-password-email">
            <ChangePasswordEmail Loading={loading}/>
          </Route>
          <Route path="/dashboard/orders">
            <Orders Loading={loading}/>
          </Route>
          <Route path="/dashboard/orders-history">
            <Orders Loading={loading}/>
          </Route>
          <Route path="/dashboard/transactions">
            <Transactions Loading={loading}/>
          </Route>
          <Route path="/dashboard/transactions-history">
            <Transactions Loading={loading}/>
          </Route>
          <Route path="/dashboard/deals">
            <Deals Loading={loading}/>
          </Route>
          <Route path="/dashboard/withdraw">
            <Withdraw Loading={loading}/>
          </Route>
          <Route path="/dashboard/deposit-history">
            <Deposit Loading={loading}/>
          </Route>
          <Route path="/dashboard/refferal-code">
            <RefferalCode Loading={loading}/>
          </Route>
          <Route path="/dashboard/invite-friends">
            <RefferalList Loading={loading}/>
          </Route>
          <Route path="/dashboard/purification">
            <Purification Loading={loading}/>
          </Route>
          <Route path="/dashboard/wallet">
            <Wallet Loading={loading}/>
          </Route>
          <Route path="/dashboard/financial">
            <Financial Loading={loading}/>
          </Route>
          <Route path="/dashboard/financial-inner">
            <Financial Loading={loading}/>
          </Route>
          <Route path="/dashboard/deposit-rial">
            <DepositRial Loading={loading}/>
          </Route> 
        </Switch> 
        </div>
        
        
        
        </React.Fragment>
    )
}

export default MainContent
