import React from 'react'
//import Router
import {
    Link
  } from "react-router-dom";

const AccountVerifyStart = () => {
    return (
        <div className="AccountVerifyWrapper">
            <div className="AccountVerifyPanel ThemeLayer2 ThemeBorder">
                <img src="./Logo/logo.png" alt="Paypoool Logo" className="AccountVerifyLogo"/>
                <span className="AccountVerifyLogoText">Pay<span className="AccountVerifyLogoText AccountVerifyLogoText2">poool</span></span>
                <span className="AccountVerifyIntroText">به پلتفرم تجارت ارز دیجیتال پی پول خوش آمدید!</span>
                <span className="AccountVerifyDescription1 ThemeText2">برای استفاده از امکانات سایت و ترید ارز های دیجیتال</span>
                <span className="AccountVerifyDescription2 ThemeText2">بر روی شروع فرایند احراز هویت کلیک کنید.</span>
                <div className="StartAccountVerification "><span><Link to="/account-verification">شروع فرایند احراز هویت</Link></span></div>
            </div>
        </div>
    )
}

export default AccountVerifyStart
