import React from 'react'

import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import AccountBoxIcon from '@material-ui/icons/AccountBox';
import MailIcon from '@material-ui/icons/Mail';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import LockIcon from '@material-ui/icons/Lock';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}


const SignUp = () => {

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

    return (
        <div className="SignUpWrapper">
            <div className="SignUpContainer ThemeLayer2">
                <div className="SignUpInner">
                <div className="SignUpContainerHeader">
                <   AccountBoxIcon />
                    <span>ایجاد حساب کاربری</span>
                </div>
                 
                <div className="SignUpContainerBody">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                        >
                        <Tab label="ایمیل" {...a11yProps(0)} />
                        <Tab label="تلفن همراه" {...a11yProps(1)} />
                        </Tabs>
           
                    <TabPanel value={value} index={0}>
                        <React.Fragment>
                        <ul className="SignUpContainerBodyUl">
                            <li><MailIcon /><input type="text" placeholder="ایمیل"/></li>
                            <li><LockIcon /><input type="text" placeholder="رمز عبور"/></li>
                            <li><LockIcon /><input type="text" placeholder="تکرار رمز عبور"/></li>
                            <li><SupervisedUserCircleIcon /><input type="text" placeholder="کد معرف (اختیاری)"/></li>
                        </ul>
                       <div className="SignUpContainerChekckOut">
                        <input type="checkbox"/>
                        <span><span>قوانین و ضوابط</span> پِی پول را مطالعه کردم و قبول میکنم</span>
                       </div>
                       <div className="SignUpSubmit"><span>ثبت نام</span></div>
                        </React.Fragment>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                    <React.Fragment>
                        <ul className="SignUpContainerBodyUl">
                            <li><PhoneAndroidIcon /><input type="text" placeholder="تلفن همراه"/></li>
                            <li><LockIcon /><input type="text" placeholder="رمز عبور"/></li>
                            <li><LockIcon /><input type="text" placeholder="تکرار رمز عبور"/></li>
                            <li><SupervisedUserCircleIcon /><input type="text" placeholder="کد معرف (اختیاری)"/></li>
                        </ul>
                       <div className="SignUpContainerChekckOut">
                        <input type="checkbox"/>
                        <span><span>قوانین و ضوابط</span> پِی پول را مطالعه کردم و قبول میکنم</span>
                       </div>
                       <div className="SignUpSubmit"><span>ثبت نام</span></div>
                        </React.Fragment>
                    </TabPanel>
                </div>

            </div>
                </div>

            <div className="LoginContainer">
                <div className="LoginPanel ThemeLayer2 ThemeBorder ThemeShadow">
                    <img src="https://www.paypalobjects.com/webstatic/icon/pp258.png" alt="" />
                    <span className="LoginLogoText">Pay<span className="LoginLogoText LoginLogoText2">poool</span></span>
                    <span className="LoginDescription">سریع ٬ راحت و مناسب ترین راه برای خرید و فروش ارز دیجیتال</span>
                    <div className="LoginButton ThemeShadow"><span>ورود به حساب کاربری</span></div>
                    <div className="LoginGoogleButton ThemeShadow"><span>Sign in with Google</span><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/1200px-Google_%22G%22_Logo.svg.png" alt="" /></div>
                </div>
            </div>
        </div>
    )
}

export default SignUp
