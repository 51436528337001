import React,{useState} from 'react'
//import Material Ui Icons
import CloseIcon from '@material-ui/icons/Close';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
 //import Router
 import {
    Link
} from "react-router-dom";

const Purification = () => {

    const [purification , setPurification] = useState(false);

    return (
        <div className="PurificationWrapper">
            <div className="PurificationPanel ThemeBorder ThemeShadow ThemeLayer2">
            <div className="BankAddAccountPanelCloseWrapper"><Link to="/dashboard/invite-friends"><CloseIcon className="BankAddAccountPanelClose"/></Link></div>
                <CardGiftcardIcon className="ThemeText3" />
                {purification ? 
                    <React.Fragment>
                        <span className="AuthenticationTitle1">تسویه کارمزد</span>
                        <span className="AuthenticationTitle1 ThemeText3">کاربرگرامی</span>
                        <span className="AuthenticationTitle5 ThemeText3">تسویه کارمزد شما با موفقیت انجام شد</span>
                        <Link to="/dashboard"><div className="PurificationAction">
                            <span>بازگشت به داشبورد</span>
                        </div></Link>
                    </React.Fragment>
                :

                    <React.Fragment>
                        <span className="AuthenticationTitle1">تسویه کارمزد</span>
                        <span className="AuthenticationTitle2">کارمزد شما بعد از کلیک بر روی دکمه تسویه کارمزد به حساب ریالی شما منتقل میشود</span>
                        <div className="PurificationPriceContainer">
                            <span className="ThemeText2">مبلغ قابل تسویه به تومان</span>
                            <input type="text" className="ThemeBorder" placeholder="مبلغ را وارد کنید"/>
                        </div>
                        <div className="PurificationAction" onClick={() => setPurification(!purification)}>
                            <span>تصویه کارمزد</span>
                        </div>
                    </React.Fragment>
                }
            </div>
        </div>
    )
}

export default Purification
