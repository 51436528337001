import React,{ useState } from 'react';
import MainContent from './components/MainContent/MainContent';
import SignUp from './components/SignUp/SignUp';
import IndexPgae from './components/IndexPage/IndexPgae';
import Login from './components/SignUp/Login';
//import Theme components
import {ThemeProvider} from "styled-components";
import { GlobalStyles } from "./components/Theme/globalStyles";
import { lightTheme, darkTheme } from "./components/Theme/Themes"
//import Router
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Icon from './components/Icon/Icon';



function App() {

  //Dark mode Toggle
  const [theme, setTheme] = useState('light');
  const themeToggler = () => {
    theme === 'light' ? setTheme('dark') : setTheme('light')
  }

  //Language Toggle
  const [language , setLanguage] = useState('fa');
  // const languageToggler = () => {
  //   language === 'fa' ? setLanguage('en') : setTheme('fa')
  // }

  //SideBar Toggle 
  const [toggle , setToggle] = useState(false);
  const sidebarToggle = () => {
    toggle === false ? setToggle(true) : setToggle(false)
  }

  //Mobile SideBar Toggle 
  const [toggleM , setToggleM] = useState(true);
  const sidebarToggleM = () => {
    toggleM === false ? setToggleM(true) : setToggleM(false)
  }

  const [loading , setLoading] = useState(true);
  


  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      
      <GlobalStyles/>
    <div className="App">

          {loading === true ? 
            <div className="LoadingWrapper">
              <div className="LoadingContainer ThemeLayer3">
                <img src="https://i.postimg.cc/CKWWvnSj/output-onlinegiftools.gif" alt="" />
                <span>PayPoool.com</span>
              </div>
             </div> 
          : null}

          

      <Router>
      <Switch>
        <Route path="/" exact>
          <IndexPgae />
        </Route>
        <Route path="/dashboard">
          <MainContent loading={setLoading}  SidebarToggleMod={toggle} SidebarToggler={sidebarToggle} setTheme={themeToggler} ThemeMode={theme} SidebarTogglerM={sidebarToggleM} SidebarToggleModM={toggleM}  setLanguage={setLanguage} languageMode={language} />
        </Route>
        <Route path="/signup" exact>
            <SignUp />
          </Route> 
          <Route path="/login" exact>
            <Login />
          </Route> 

          <Route path="/icon" exact>
            <Icon />
          </Route> 
      </Switch>
        
       
        
      </Router>
      

    
    </div>
    
    </ThemeProvider>
  );
}

export default App;
