import React from 'react'
//import Material Ui Icons
import CloseIcon from '@material-ui/icons/Close';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
 //import Router
 import {
    Link
} from "react-router-dom";

const RefferalCode = () => {
    return (
        <div className="RefferalCodeWrapper">
            <div className="RefferalCodePanel ThemeBorder ThemeShadow ThemeLayer3">
                <div className="BankAddAccountPanelCloseWrapper"><Link to="/dashboard/invite-friends"><CloseIcon className="BankAddAccountPanelClose"/></Link></div>
                <CardGiftcardIcon />
                <span className="AuthenticationPhoneTitle1">دعوت از دوستان (کد ریفرال)</span>
                <ul>
                    <li>
                        <div className="RefferalCodeLiNumber"><span>1</span></div>
                        <span className="RefferalCodeLiText">۲۰٪ از کارمزد تمام معاملات و مبادلات کسانی که با لینک  کد شما ثبت نام میکنند به شما پرداخت میشود.</span>
                    </li>
                    <li>
                        <div className="RefferalCodeLiNumber"><span>2</span></div>
                        <span className="RefferalCodeLiText">برای همین منظور جهت دعوت از دوستان و آشنایان خود ٬ از کد و لینک اختصاصی خودتان استفاده کنید</span>
                    </li>
                </ul>

                <div className="RefferalCodeItemContainer">
                <div className="RefferalCodeItemBodyHeader"><span className="ThemeText2">کد ارجاع شما</span></div>
                    <div className="RefferalCodeItemBody">
                        <div className="ThemeText2 ThemeLayer3"><FileCopyIcon /></div>
                        <input type="text" value="KLJHSDKJLFHNVJKFLDVHSDDKJH" className="ThemeBorder"/>
                    </div>
                </div>
                <div className="RefferalCodeItemContainer">
                <div className="RefferalCodeItemBodyHeader"><span className="ThemeText2">لینک ارجاع شما</span></div>
                    <div className="RefferalCodeItemBody">
                        <div className="ThemeText2 ThemeLayer3"><FileCopyIcon /></div>
                        <input type="text" value="KLJHSDKJLFHNVJKFLDVHSDDKJH" className="ThemeBorder"/>
                    </div>
                </div>

                <div className="RefferalCodePanelSubmit">
                    <Link to="/dashboard/invite-friends"><span>مشاهده لیست دعوت شدگان</span></Link>
                </div>
                        
            </div>
        </div>
    )
}

export default RefferalCode
