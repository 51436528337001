import React from 'react'
//import Router
import {
    Link
} from "react-router-dom";

const HeaderButton2 = ({Icon,Name,LinkAddress}) => {
    return (
        <React.Fragment>
            <Link to={LinkAddress}><div className="HeaderButton2 ThemeButton2 ThemeShadow"><span>{Name}</span>{Icon}</div></Link>
        </React.Fragment>
    )
}

export default HeaderButton2
