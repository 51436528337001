import React from 'react'
import { useHistory } from "react-router-dom";
//import Material Ui Icons
import CloseIcon from '@material-ui/icons/Close';
import HomeIcon from '@material-ui/icons/Home';
import ExposureIcon from '@material-ui/icons/Exposure';
import Brightness3Icon from '@material-ui/icons/Brightness3';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import HttpsIcon from '@material-ui/icons/Https';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import HelpIcon from '@material-ui/icons/Help';
import HistoryIcon from '@material-ui/icons/History';
import FlipToBackIcon from '@material-ui/icons/FlipToBack';
import CachedIcon from '@material-ui/icons/Cached';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import SecurityIcon from '@material-ui/icons/Security';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import ListAltIcon from '@material-ui/icons/ListAlt';
import TransformIcon from '@material-ui/icons/Transform';
import ReceiptIcon from '@material-ui/icons/Receipt';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import QueueIcon from '@material-ui/icons/Queue';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
  //import Sidebar 
import {Navigation} from 'react-minimal-side-navigation';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';

const SidebarMobile = ({SidebarTogglerM , SidebarToggleModM , setTheme , ThemeMode}) => {
    const history = useHistory();
    return (
       <React.Fragment>
           {SidebarToggleModM === false ?  
           <div className="SidebarMobileWrapper">
               <div className="SidebarMobileActions">
               <div className="LangToggleM">
               <img src="https://lipis.github.io/flag-icon-css/flags/4x3/ir.svg" alt="" />
           </div>
           {ThemeMode === 'light' ?  <Brightness3Icon onClick={setTheme} className="UserActionIconsMobile"/> :  <WbSunnyIcon onClick={setTheme} className="UserActionIconsMobile"/>}
               </div>
            <CloseIcon className="SidebarMobileWrapperClose" onClick={SidebarTogglerM}/>
            <Navigation
            className="SidebarMobile"
            activeItemId="/dashboard"
            onSelect={({itemId}) => {
                history.push(itemId);
               
            }}
            items={[
              {
                title: '(Dashboard) داشبورد',
                itemId: '/dashboard',
                elemBefore: () =>  <HomeIcon  className="SidebarIcon" />,
                // you can use your own custom Icon component as well
                // icon is optional
                // elemBefore: () => <Icon name="inbox" />,
              },
              {
                title: '(Financial) امور مالی',
                itemId: '/dashboard/financial',
                elemBefore: () => <ExposureIcon  className="SidebarIcon" />,
                subNav: [
                  {
                    title: 'واریز ریالی',
                    itemId: '/dashboard/deposit-rial',
                    elemBefore: () => <ViewCarouselIcon className="SidebarIcon" />,
                  },
                  {
                    title: 'برداشت',
                    itemId: '/dashboard/financial',
                    elemBefore: () => <MergeTypeIcon className="SidebarIcon" />,
                  },
                  {
                    title: 'افزودن کارت و حساب بانکی',
                    itemId: '/dashboard/bank-add',
                    elemBefore: () => <QueueIcon className="SidebarIcon" />,
                  },
                  {
                    title: 'تراکنش ها',
                    itemId: '/dashboard/transactions',
                    elemBefore: () => <AttachMoneyIcon className="SidebarIcon" />,
                  },
                ],
              },
              {
                title: '(Wallet) کیف پول',
                itemId: '/dashboard/wallet',
                elemBefore: () => <ExposureIcon  className="SidebarIcon" />,
                // subNav: [
                //   {
                //     title: 'Projects',
                //     itemId: '/management/projects',
                //   },
                //   {
                //     title: 'Members',
                //     itemId: '/management/members',
                //   },
                // ],
              },
              {
                title: '(Trade با تومان) معاملات ارز',
                itemId: '/dashboard/toman-Trade',
                elemBefore: () =>  <AccountBalanceWalletIcon  className="SidebarIcon" />,
                // subNav: [
                //   {
                //     title: 'Teams',
                //     itemId: '/management/teams',
                //   },
                // ],
              },
              {
                title: '(Trade با تتر) معاملات ارز',
                itemId: '/dashboard/teter-Trade',
                elemBefore: () =>  <ImportExportIcon  className="SidebarIcon" />,
                // subNav: [
                //   {
                //     title: 'Teams',
                //     itemId: '/management/teams',
                //   },
                // ],
              },
              {
                title: '(Spot) خرید و فروش ارز',
                itemId: '/dashboard/spot',
                elemBefore: () =>  <ImportExportIcon  className="SidebarIcon" />,
                // subNav: [
                //   {
                //     title: 'Teams',
                //     itemId: '/management/teams',
                //   },
                // ],
              },
              {
                title: '(Exchange) تبدیل ارز',
                itemId: '/dashboard/exchange',
                elemBefore: () =>  <CachedIcon  className="SidebarIcon" />,
                // subNav: [
                //   {
                //     title: 'Teams',
                //     itemId: '/management/teams',
                //   },
                // ],
              },
              {
                title: '(Open Orders) سفارشات باز',
                itemId: '/dashboard/open-orders',
                elemBefore: () =>  <FlipToBackIcon  className="SidebarIcon" />,
                // subNav: [
                //   {
                //     title: 'Teams',
                //     itemId: '/management/teams',
                //   },
                // ],
              },
              {
                title: '(History) تاریخچه',
                itemId: '/dashboard/orders',
                elemBefore: () =>  <HistoryIcon  className="SidebarIcon" />,
                subNav: [
                    {
                        title: 'سفارش ها',
                        itemId: '/dashboard/orders',
                        elemBefore: () =>  <ListAltIcon  className="SidebarIcon" />,
                        
                        },
                    {
                        title: 'معاملات',
                        itemId: '/dashboard/deals',
                        elemBefore: () =>  <TransformIcon  className="SidebarIcon" />,
                        
                        },
                    {
                        title: 'تراکنش ها',
                        itemId: '/dashboard/transactions',
                        elemBefore: () =>  <ReceiptIcon  className="SidebarIcon" />,
                        
                        },
                    {
                        title: 'واریز ها',
                        itemId: '/dashboard/deposit-history',
                        elemBefore: () =>  <MonetizationOnIcon  className="SidebarIcon" />,
                        
                        },
                    {
                        title: 'برداشت ها',
                        itemId: '/dashboard/withdraw',
                        elemBefore: () =>  <MergeTypeIcon  className="SidebarIcon" />,
                        
                        },
                ],
              },
              {
                title: '(Security) امنیت',
                itemId: '/dashboard/security',
                elemBefore: () =>  <HttpsIcon  className="SidebarIcon" />,
                subNav: [
                  {
                    title: 'وضعیت امنیتی',
                    itemId: '/dashboard/security',
                    elemBefore: () =>  <SecurityIcon  className="SidebarIcon" />,
                    
                  },
                  {
                    title: 'تاریخچه لاگین',
                    itemId: '/dashboard/login-history',
                    elemBefore: () =>  <SettingsBackupRestoreIcon  className="SidebarIcon" />,
                  },
                ],
              },
              {
                title: '(Refferal Code) دعوت دوستان',
                itemId: '/dashboard/invite-friends',
                elemBefore: () =>  <DeviceHubIcon  className="SidebarIcon" />,
                // subNav: [
                //   {
                //     title: 'Teams',
                //     itemId: '/management/teams',
                //   },
                // ],
              },
              {
                title: '(Support) پشتیبانی',
                itemId: '/dashboard/support',
                elemBefore: () =>  <HelpIcon  className="SidebarIcon" />,
                // subNav: [
                //   {
                //     title: 'Teams',
                //     itemId: '/management/teams',
                //   },
                // ],
              },
            ]}
          />
        </div> : null}
       </React.Fragment>
    )
}

export default SidebarMobile
