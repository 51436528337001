import React from 'react'

const PanelHeader = ({Title,Action1,Action2,Action3}) => {
    return (
        <React.Fragment>
            <div className="PanelHeader ThemeLayer ThemeBorderBottom">
                <h2 className="PanelHeaderTitle ThemeText5">{Title}</h2>
                <div className="PanelHeaderActions">
                    {Action1}{Action2}{Action3}
                </div>
            </div>
        </React.Fragment>
    )
}

export default PanelHeader
