import React , {useState} from 'react'

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
//import material ui icons
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import Brightness3Icon from '@material-ui/icons/Brightness3';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
//import material ui avatar
// import Avatar from '@material-ui/core/Avatar';
//import Gsap
import gsap from 'gsap';
import { CSSPlugin } from 'gsap/CSSPlugin'
//import Router
import {
    Link
  } from "react-router-dom";

const Navbar = ({setTheme ,ThemeMode , setLanguage , languageMode , SidebarToggler , SidebarTogglerM}) => {

    gsap.registerPlugin(CSSPlugin)

    const [DropDown, setDropDown] = useState(false);
    const [NotificationDropDown , setNotificationDropDown] = useState(false);


      // const classes = useStyles();

      // let ThemeIcon = useRef(null);
      // let tl = new gsap.timeline();

      // useEffect(() => {
        
        

      // })
    
      const handleClick = () => {
        setDropDown((prev) => !prev);
      };
    
      const handleClickAway = () => {
        setDropDown(false);
      };

      const handleClick2 = () => {
        setNotificationDropDown((prev) => !prev);
      };
    
      const handleClickAway2 = () => {
        setNotificationDropDown(false);
      };

      // const Test = () => {

      //   // setTheme()
      //   // console.log(ThemeIcon);
      //   // console.log(tl);
      //   // tl.start();
      //   tl.from(ThemeIcon , .5 , {y:10 , opacity : .2 , rotate : 90 , ease : Power3.easeOut});
       
        
      // }



    return (
        <div className="NavbarWrapper ThemeShadow ThemeBorder ThemeLayer2">
    
           <div className="LangToggle" onClick={setLanguage}>
               <img src="https://lipis.github.io/flag-icon-css/flags/4x3/ir.svg" alt="" />
           </div>
           <div className="NavbarActions">
               <ClickAwayListener onClickAway={handleClickAway}>
            <div className="UserControl ThemeLayer4 ThemeBorder" onClick={handleClick}>
                        {DropDown ? <div className="NavbarActionsDropDown ThemeLayer2 ThemeBorder">
                        <ul className="UserControlUl">
                        <Link to="/dashboard/profile"><li className="">
                                <span className="ThemeText2">مشاهده پروفایل</span>
                            </li></Link>
                            <Link to="/profile"><li className="">
                                <span className="ThemeText2">تنظیمات</span>
                            </li></Link>
                            <Link to="/profile"><li className="">
                                <span className="ThemeText2">خروج</span>
                            </li></Link>
                        </ul>
                    </div> : null}
                        <PermIdentityIcon />
                            <span className="UserEmail ThemeText2">paypoool@gmail.com</span>
                            <ArrowDropDownIcon />
               </div>
               </ClickAwayListener>
               <ClickAwayListener onClickAway={handleClickAway2}>
               <div className="NotificationsNoneIconWrapper"><NotificationsNoneIcon className="UserActionIcons" onClick={handleClick2}/>
                {NotificationDropDown ? <div className="NotificationDropDown ThemeLayer3 ThemeBorder">
                    <span className="NotificationDropDownEmpty ThemeText1">پیامی یافت نشد!</span>
                {/* <ul>
                    <li className="">
                        <span className="ThemeText2">مشاهده پروفایل</span>
                    </li>
                    <li className="">
                        <span className="ThemeText2">تنظیمات</span>
                    </li>
                    <li className="">
                        <span className="ThemeText2">خروج</span>
                    </li>
                </ul> */}
            </div> : null}
               </div>
               </ClickAwayListener>
               {ThemeMode === 'light' ?  <Brightness3Icon onClick={setTheme} className="UserActionIcons" /> :  <WbSunnyIcon onClick={setTheme} className="UserActionIcons"/>}
           </div>
           <div className="NavbarItems">
                <ul>
                    <li className="ThemeText1">مارکت</li>
                    <li className="ThemeText1">پلتفرم تومانی</li>
                    <li className="ThemeText1">پلتفرم تتری</li>
                    <li className="ThemeText1">کارمزدها</li>
                    <li className="ThemeText1">ووچر</li>
                    <li className="ThemeText1">سوالات متداول</li>
                    <li className="ThemeText1">راهنمای استفاده</li>
                </ul>
           </div> 
           <div className="LogoWrapper">
                <img src="https://i.postimg.cc/637BJTDQ/Logo.png" alt="Paypoool Logo" className="NavbarLogo"/>
                <span className="LogoText">Pay<span className="LogoText LogoText2">poool</span></span>
           </div>
           <div className="MenuToggle" onClick={SidebarToggler}>
               <MenuIcon className="MenuToggleIcon"/>
               
               </div> 
               
                

                

           <div className="MenuToggleMobile" onClick={SidebarTogglerM}>
               <MenuIcon className="MenuToggleIconMobile"/>
               {/* <Hamburger size={32} /> */}
               </div> 
        </div>
    )
}

export default Navbar
