import React from 'react'
import LockIcon from '@material-ui/icons/Lock';
import MailIcon from '@material-ui/icons/Mail';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import HomeIcon from '@material-ui/icons/Home';
import UpdateIcon from '@material-ui/icons/Update';
import GetAppIcon from '@material-ui/icons/GetApp';
//import Router
import {
    Link
  } from "react-router-dom";

const Security = () => {
    return (
        <div className="SecurityWrapper">

            <ul>
                <li className="ThemeBorder ThemeShadow ThemeLayer2">
                    <div className="SecurityLiHeader ThemeBorderBottom">
                        <h3>امنیت (تغییر رمز ورود)</h3>
                        <Link to="/dashboard/change-password-email"><div className="SecurityLiHeaderAction ThemeLayer3 ThemeBorder">
                            <span className="ThemeText2">فراموشی رمز عبور</span>
                            <LockIcon />
                        </div></Link>
                    </div>
                    <div className="SecurityContainer">
                        <LockIcon />
                        <h3 className="ThemeText2 SecurityTitle">تغییر رمز ورود</h3>
                        <span className="SecurityDescription">برای امنیت حساب کاربری٬ به صورت دوره ای رمز ورودی را تغییر دهید</span>
                    </div>
                    <div className="SecurityAction">
                        <Link to="/dashboard/change-password"><div className="SecurityActionCall ThemeShadow">
                            <span>برای تغییر رمز ورود کلیک کنید</span>
                        </div></Link>
                    </div>
                </li>
                <li className="ThemeBorder ThemeShadow ThemeLayer2">
                    <div className="SecurityLiHeader ThemeBorderBottom">
                        <h3>تصدیق پیامکی</h3>
                        <Link to="/dashboard/wallet"><div className="SecurityLiHeaderAction ThemeLayer3 ThemeBorder">
                            <span className="ThemeText2">بازگشت به کیف پول</span>
                            <AccountBalanceWalletIcon />
                        </div></Link>
                    </div>
                    <div className="SecurityContainer">
                        <MailIcon />
                        <h3 className="ThemeText2 SecurityTitle">تصدیق پیامکی</h3>
                        <span className="SecurityDescription">امنیت حساب خود را با استفاده از تصدیق پیامکی افزایش دهید</span>
                    </div>
                    <div className="SecurityAction">
                    <Link to="/dashboard/authentication-phone"><div className="SecurityActionCall ThemeShadow">
                            <span>فعال سازی</span>
                        </div></Link>
                    </div>
                </li>
                <li className="ThemeBorder ThemeShadow ThemeLayer2">
                    <div className="SecurityLiHeader ThemeBorderBottom">
                        <h3>تصدیق دوعاملی گوگل</h3>
                        <Link to="#"><div className="SecurityLiHeaderAction ThemeLayer3 ThemeBorder">
                            <span className="ThemeText2">دانلود اپلیکیشن</span>
                            <GetAppIcon />
                        </div></Link>
                    </div>
                    <div className="SecurityContainer">
                        <LockIcon />
                        <h3 className="ThemeText2 SecurityTitle">تصدیق دوعاملی</h3>
                        <span className="SecurityDescription">امنیت حساب کاربری خود را با استفاده از تصدیق ۲ عاملی اپلیکیشن Google Authenticator افزایش دهید</span>
                    </div>
                    <div className="SecurityAction">
                    <Link to="/dashboard/authentication-app"><div className="SecurityActionCall ThemeShadow">
                            <span>فعال سازی</span>
                        </div></Link>
                    </div>
                </li>
                <li className="ThemeBorder ThemeShadow ThemeLayer2">
                    <div className="SecurityLiHeader ThemeBorderBottom">
                        <h3>تاریخچه لاگین</h3>
                        <Link to="/dashboard"><div className="SecurityLiHeaderAction ThemeLayer3 ThemeBorder">
                            <span className="ThemeText2">بازگشت به داشبورد</span>
                            <HomeIcon />
                        </div></Link>
                    </div>
                    <div className="SecurityContainer">
                        <UpdateIcon />
                        <h3 className="ThemeText2 SecurityTitle">تاریخچه لاگین</h3>
                        <span className="SecurityDescription">در این قسمت ٬ میتوانید تاریخ های دقیق ورود موفق و ناموفق به حساب کاربری خود را مشاهده کنید</span>
                    </div>
                    <div className="SecurityAction">
                        <Link to="/dashboard/login-history"><div className="SecurityActionCall ThemeShadow">
                            <span>مشاهده تاریخچه لاگین</span>
                        </div></Link>
                    </div>
                </li>
            </ul>

        </div>
    )
}

export default Security
