import React,{useState} from 'react'
import ReactCodeInput from 'react-code-input';
//import Material Ui Icons
import CloseIcon from '@material-ui/icons/Close';
import CachedIcon from '@material-ui/icons/Cached';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LockIcon from '@material-ui/icons/Lock';
 //import Router
 import {
    Link
} from "react-router-dom";

const ChangePasswordEmail = () => {

    const [sendCode , setSendCode] = useState('GetEmail');

    return (
        <div className="ChangePasswordEmailWrapper">
             <div className="ChangePasswordEmailPanel ThemeBorder ThemeShadow ThemeLayer2">
             <div className="BankAddAccountPanelCloseWrapper"><Link to="/profile"><CloseIcon className="BankAddAccountPanelClose"/></Link></div>
                <LockIcon />
    
              
                        <span className="AuthenticationTitle1">فراموشی رمز عبور</span>
                        {sendCode === 'GetEmail' ?
                            <React.Fragment>
                                <span className="AuthenticationTitle3">لطفا ایمیل خود را جهت بازیابی رمز عبور وارد کنید</span>
                                <div className="ChangePasswordEmailItemBody"><div ><MailOutlineIcon /></div><input type="text" className="ThemeBorder"/></div>
                                <span className="AuthenticationTitle4">یک ایمیل جهت تنظیم مجدد رمز عبور برای شما ارسال خواهد شد</span>
                                <div className="AuthenticationResend">
                                    <CachedIcon />
                                    <span className="ThemeText2">ارسال مجدد</span>
                                </div>
                                <div className="ChangePasswordEmailAction" onClick={() => setSendCode('GetCode')}>
                                    <span>ارسال</span>
                                </div>
                            </React.Fragment>
                        :

                            ( sendCode === 'GetCode' ?

                            <React.Fragment>
                            <span className="AuthenticationTitle3">لطفا کد ۶ رقمی ارسالی به ایمیل خود را وارد کنید</span>
                            <ReactCodeInput type='number' fields={6} />
                            <div className="AuthenticationResend">
                                <CachedIcon />
                                <span className="ThemeText2">ارسال مجدد</span>
                            </div>
                            <div className="AuthenticationPhoneAction" onClick={() => setSendCode('GetNewPassword')}>
                                <span>تایید</span>
                            </div>
                            </React.Fragment>

                                :
                                <React.Fragment>
                                    <div className="ChangePasswordEmailItem">
                                        <div className="ChangePasswordEmailItemHeader"><span className="ThemeText2">رمز ورود جدید</span></div>
                                        <div className="ChangePasswordEmailItemBody"><input type="text" placeholder="رمز ورود جدید" className="ThemeBorder"/></div>
                                    </div>
                                    <div className="ChangePasswordEmailItem">
                                        <div className="ChangePasswordEmailItemHeader"><span className="ThemeText2">تکرار رمز ورود جدید</span></div>
                                        <div className="ChangePasswordEmailItemBody"><input type="text" placeholder="تکرار رمز ورود جدید" className="ThemeBorder"/></div>
                                    </div>
                                    <div className="ChangePasswordEmailAction" onClick={() => setSendCode('')}>
                                        <span>تغییر رمز ورود</span>
                                    </div>
                                </React.Fragment>

                            )
                            
                        }
                
            </div>
        </div>
    )
}

export default ChangePasswordEmail
