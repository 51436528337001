import React , {useState} from 'react'  

const NotificationWi = ({MainText , SubText , NotifUl}) => {

    const [notification, setNotification] = useState(true);

    return (
       <React.Fragment>
            { notification ? 
                <div className="NotificationWrapperWi ThemeLayer3 ThemeBorder">
                    <div className="NotificationWrapperWiInner">
                        <div className="NotificationClose" onClick={() => setNotification(!notification)}>
                            <span>متوجه شدم</span>
                        </div>
                        <span className="ThemeText1"><b className="ThemeText2">{SubText} </b>  {MainText} </span>
                    </div>
                    
                    <div className="NotificationWrapperWiItems">
                        <ul>
                            <li className="ThemeText1">اطلاعات حساب کاربری (پروفایل )، باید منطبق با کارت ملی شما باشد.</li>
                            <li className="ThemeText1">از واگذاری اطلاعات شخصی خود به دیگران،برای افتتاح حساب کاربری،به بهانه ی کسب درآمد؛ اکیدا خودداری فرمایید.</li>
                            <li className="ThemeText1">از اجاره دادن حساب کاربری خود به دیگران و هرگونه پیشنهاد ساخت حساب کاربری از طرف افراد ناشناس در فضای مجازی خودداری نموده، چرا که این امر توسط پلیس فتا، کلاهبرداری و پولشویی تلقی گردیده و تمام عواقب حقوقی و قضایی آن، بر عهده ی شخص شما می باشد.</li>
                            <li className="ThemeText1">در تمام مراحل استفاده از سایت، فیلتر شکن خود را غیر فعال نمایید.</li>
                        </ul>
                    </div>
                </div> 
            : null }
       </React.Fragment>
    )
}

export default NotificationWi
