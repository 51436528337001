import React from 'react'
import { useHistory } from "react-router-dom";
//import material ui icons
import SecurityIcon from '@material-ui/icons/Security';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import ListAltIcon from '@material-ui/icons/ListAlt';
import TransformIcon from '@material-ui/icons/Transform';
import ReceiptIcon from '@material-ui/icons/Receipt';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import MergeTypeIcon from '@material-ui/icons/MergeType';
//import Sidebar 
import {Navigation} from 'react-minimal-side-navigation';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';

const Sidebar = ({SidebarToggleMod,Loading}) => {
    const history = useHistory();


    return (
        <div className={SidebarToggleMod === false ? 'SidebarWrapper ThemeShadow ThemeLayer2 ThemeBorder r0 w18' : 'SidebarWrapper ThemeShadow ThemeLayer2 ThemeBorder r18 w0'}>
            {/* <ul>
                
            <NavLink to="/dashboard" activeClassName="ThemeLayer3"><li>
                    <span className="SidebarText">(Dashboard) داشبورد</span>
                    <HomeIcon  className="SidebarIcon" />
                </li></NavLink>
                <NavLink to="/Financial" activeClassName="ThemeLayer3"><li>
                    <span className="SidebarText">(Financial) امور مالی</span>
                    <ExposureIcon  className="SidebarIcon" />
                </li></NavLink>
                <NavLink to="/Wallet" activeClassName="ThemeLayer3"><li>
                    <span className="SidebarText">(Wallet) کیف پول</span>
                    <AccountBalanceWalletIcon  className="SidebarIcon" />
                </li></NavLink>
                <NavLink to="/toman-Trade" activeClassName="ThemeLayer3"><li>
                    <span className="SidebarText">(Trade با تومان) معاملات ارز</span>
                    <ImportExportIcon  className="SidebarIcon" />
                </li></NavLink>
                <NavLink to="/teter-Trade" activeClassName="ThemeLayer3"><li >
                    <span className="SidebarText">(Trade با تتر) معاملات ارز</span>
                    <ImportExportIcon  className="SidebarIcon" />
                </li></NavLink>
                <NavLink to="/spot" activeClassName="ThemeLayer3"><li >
                    <span className="SidebarText">(Spot) خرید و فروش ارز</span>
                    <CompareArrowsIcon  className="SidebarIcon" />
                </li></NavLink>
                <NavLink to="/exchange" activeClassName="ThemeLayer3"><li >
                    <span className="SidebarText">(Exchange) تبدیل ارز</span>
                    <CachedIcon  className="SidebarIcon" />
                </li></NavLink>
                <NavLink to="/open-orders" activeClassName="ThemeLayer3"><li >
                    <span className="SidebarText">(Open Orders) سفارشات باز</span>
                    <FlipToBackIcon  className="SidebarIcon" />
                </li></NavLink>
                <NavLink to="/history" activeClassName="ThemeLayer3"><li >
                    <span className="SidebarText">(History) تاریخچه</span>
                    <HistoryIcon  className="SidebarIcon" />
                </li></NavLink>
                <NavLink to="/security" activeClassName="ThemeLayer3"><li >
                    <span className="SidebarText">(Security) امنیت</span>
                    <HttpsIcon  className="SidebarIcon" />
                </li></NavLink>
                <NavLink to="/refferal-code" activeClassName="ThemeLayer3"><li >
                    <span className="SidebarText">(Refferal Code) دعوت دوستان</span>
                    <DeviceHubIcon  className="SidebarIcon" />
                </li></NavLink>
                <NavLink to="/support" activeClassName="ThemeLayer3"><li >
                    <span className="SidebarText">(Support) پشتیبانی</span>
                    <HelpIcon  className="SidebarIcon" />
                </li></NavLink>
            </ul> */}
            <Navigation
           
            activeItemId="/dashboard"
            onSelect={({itemId}) => {
                history.push(itemId);
                Loading(true)
            }}
            items={[
              {
                title: '(Dashboard) داشبورد',
                itemId: '/dashboard',
                elemBefore: () =>  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 127.93 110.48">
                <polyline points="104.42 40.09 104.42 103.98 25.59 103.98 25.59 40.09" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="13"/>
                <polyline points="78.24 103.98 78.24 69.55 51.87 69.55 51.87 103.98" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="13"/>
                <polyline points="121.43 51.71 64.58 6.5 6.5 52.85" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="13"/>
              </svg>
              
              
              ,
                // you can use your own custom Icon component as well
                // icon is optional
                // elemBefore: () => <Icon name="inbox" />,
              },
              {
                title: '(Financial) امور مالی',
                itemId: '/dashboard/financial',
                elemBefore: () => <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92.38 100.86">
                <polyline points="5.5 5.5 5.5 95.36 86.88 95.36 86.88 5.5 5.5 5.5" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="11"/>
                <line x1="64" y1="27.16" x2="28.38" y2="27.16" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="11"/>
                <g>
                  <ellipse cx="70.21" cy="54.2" rx="5.04" ry="5.34"/>
                  <ellipse cx="45.97" cy="54.2" rx="5.04" ry="5.34"/>
                  <ellipse cx="21.73" cy="54.2" rx="5.04" ry="5.34"/>
                </g>
                <g>
                  <ellipse cx="70.21" cy="71.43" rx="5.04" ry="5.34"/>
                  <ellipse cx="45.97" cy="71.43" rx="5.04" ry="5.34"/>
                  <ellipse cx="21.73" cy="71.43" rx="5.04" ry="5.34"/>
                </g>
              </svg>
              ,
                subNav: [
                  {
                    title: 'واریز ریالی',
                    itemId: '/dashboard/deposit-rial',
                    elemBefore: () => <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66.31 55.17">
                    <polyline points="56.45 34.87 63.31 34.87 63.31 3 17.05 3 17.05 12.77" fill="none" stroke="#1d1d1b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="6"/>
                    <polygon points="48.91 52.17 48.91 32.64 48.91 20.3 3 20.3 3 52.17 48.91 52.17" fill="none" stroke="#1d1d1b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="6"/>
                    <line x1="3" y1="32.13" x2="48.92" y2="32.13" fill="none" stroke="#1d1d1b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="6"/>
                    <line x1="10.37" y1="41.89" x2="26.81" y2="41.89" fill="none" stroke="#1d1d1b" strokeMiterlimit="10" strokeWidth="5"/>
                    <line x1="31.27" y1="41.89" x2="41.72" y2="41.89" fill="none" stroke="#1d1d1b" strokeMiterlimit="10" strokeWidth="5"/>
                  </svg>
                  
                  ,
                  },
                  {
                    title: 'برداشت',
                    itemId: '/dashboard/financial-inner',
                    elemBefore: () => <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.46 82.05">
                    <polygon points="89.46 16.41 89.46 59.96 89.46 77.05 5 77.05 5 16.41 89.46 16.41" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="10"/>
                    <polyline points="89.46 59.96 57.25 59.96 57.25 32.12 89.46 32.12" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="10"/>
                    <polyline points="60.65 13.44 60.65 5 11.5 5 11.5 11.93" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="10"/>
                    <line x1="70.16" y1="49.47" x2="76.55" y2="49.47" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                    <line x1="70.16" y1="42.33" x2="76.55" y2="42.33" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                    <line x1="76.55" y1="49.04" x2="76.55" y2="42.65" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                    <line x1="70.16" y1="49.04" x2="70.16" y2="42.65" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                  </svg>
                  
                  
                  ,
                  },
                  {
                    title: 'افزودن کارت و حساب بانکی',
                    itemId: '/dashboard/bank-add',
                    elemBefore: () => <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55.76 44.56">
                    <line x1="30.75" y1="18.16" x2="44.88" y2="18.16" fill="none" stroke="#1d1d1b" strokeMiterlimit="10" strokeWidth="5"/>
                    <line x1="35.13" y1="28.16" x2="44.88" y2="28.16" fill="none" stroke="#1d1d1b" strokeMiterlimit="10" strokeWidth="5"/>
                    <path d="M64,74.08c0-11.49-14.64-11.49-14.64,0" transform="translate(-37.19 -40.56)" fill="none" stroke="#1d1d1b" strokeMiterlimit="10" strokeWidth="4"/>
                    <path d="M51.07,59c0,7.23,11.11,7.23,11.11,0S51.07,51.9,51.07,59Z" transform="translate(-37.19 -40.56)" fill="none" stroke="#1d1d1b" strokeMiterlimit="10" strokeWidth="4"/>
                    <line x1="53.26" y1="2.5" x2="53.26" y2="42.06" fill="none" stroke="#1d1d1b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5"/>
                    <line x1="2.5" y1="42.06" x2="53.26" y2="42.06" fill="none" stroke="#1d1d1b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5"/>
                    <line x1="3.32" y1="2.5" x2="52.99" y2="2.5" fill="none" stroke="#1d1d1b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5"/>
                    <line x1="2.5" y1="2.5" x2="2.5" y2="42.06" fill="none" stroke="#1d1d1b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5"/>
                  </svg>
                    
                    ,
                  },
                  {
                    title: 'تراکنش ها',
                    itemId: '/dashboard/transactions',
                    elemBefore: () => <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43.86 35.87">
                    <path d="M88.19,77.47c0-12.6-19.64-12.6-19.64,0C68.55,90.56,88.19,90.56,88.19,77.47Z" transform="translate(-45.83 -53.17)" fill="none" stroke="#1d1d1b" strokeMiterlimit="10" strokeWidth="3"/>
                    <polyline points="27.87 24.4 31 27.54 36.51 22.04" fill="none" stroke="#1d1d1b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                    <path d="M78,67V58.42c0-4.33-30.18-4.33-30.18,0V81.8c0,6,19.79,7.43,22.73,1.33" transform="translate(-45.83 -53.17)" fill="none" stroke="#1d1d1b" strokeMiterlimit="10" strokeWidth="4"/>
                    <path d="M78,64.53c0,2.12-30.18,2.12-30.18,0v7.32H70.16" transform="translate(-45.83 -53.17)" fill="none" stroke="#1d1d1b" strokeMiterlimit="10" strokeWidth="4"/>
                    <line x1="2" y1="24.48" x2="22.72" y2="24.48" fill="none" stroke="#1d1d1b" strokeMiterlimit="10" strokeWidth="4"/>
                  </svg>
                  ,
                  },
                ],
              },
              {
                title: '(Wallet) کیف پول',
                itemId: '/dashboard/wallet',
                elemBefore: () => <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.46 82.05">
                <polygon points="89.46 16.41 89.46 59.96 89.46 77.05 5 77.05 5 16.41 89.46 16.41" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="10"/>
                <polyline points="89.46 59.96 57.25 59.96 57.25 32.12 89.46 32.12" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="10"/>
                <polyline points="60.65 13.44 60.65 5 11.5 5 11.5 11.93" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="10"/>
                <line x1="70.16" y1="49.47" x2="76.55" y2="49.47" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                <line x1="70.16" y1="42.33" x2="76.55" y2="42.33" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                <line x1="76.55" y1="49.04" x2="76.55" y2="42.65" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                <line x1="70.16" y1="49.04" x2="70.16" y2="42.65" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
              </svg>
              
              
              
              
              
              ,
                // subNav: [
                //   {
                //     title: 'Projects',
                //     itemId: '/management/projects',
                //   },
                //   {
                //     title: 'Members',
                //     itemId: '/management/members',
                //   },
                // ],
              },
              {
                title: '(Trade با تومان) معاملات ارز',
                itemId: '/dashboard/toman-Trade',
                elemBefore: () =>  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.84 102.69">
                <g id="HandShake">
                  <path d="M63.8,118.65a4.64,4.64,0,0,1-3.33-1.39L5.31,61.31a4.67,4.67,0,0,1-.08-6.48l32.33-34.4a4.68,4.68,0,0,1,6.5-.3L67.94,41.27a4.68,4.68,0,1,1-6.21,7L41.26,30.15,15.13,58l52,52.74a4.68,4.68,0,0,1-3.33,8Z" transform="translate(-2.46 -17.46)" stroke="#000" strokeMiterlimit="10" strokeWidth="3"/>
                  <path d="M64,118.65a4.68,4.68,0,0,1-3.33-8l52-52.74-26-27.66L68.08,48.14a4.68,4.68,0,1,1-6.49-6.74l22-21.14A4.74,4.74,0,0,1,86.93,19a4.68,4.68,0,0,1,3.29,1.47l32.32,34.4a4.67,4.67,0,0,1-.08,6.48l-55.15,56A4.66,4.66,0,0,1,64,118.65Z" transform="translate(-2.46 -17.46)" stroke="#000" strokeMiterlimit="10" strokeWidth="3"/>
                  <path d="M97,85.14a4.63,4.63,0,0,1-3.31-1.37L75.42,65.51,63,77.89a4.67,4.67,0,0,1-6.61,0L44.49,66a4.67,4.67,0,0,1,0-6.61l39-39a4.68,4.68,0,1,1,6.62,6.61L54.41,62.65,59.73,68,72.11,55.59a4.68,4.68,0,0,1,6.62,0l21.56,21.57a4.68,4.68,0,0,1-3.3,8Z" transform="translate(-2.46 -17.46)" stroke="#000" strokeMiterlimit="10" strokeWidth="3"/>
                  <path d="M76.17,106.28a4.62,4.62,0,0,1-3.3-1.37l-8-8a4.68,4.68,0,0,1,6.61-6.62l8,8a4.68,4.68,0,0,1-3.31,8Z" transform="translate(-2.46 -17.46)" stroke="#000" strokeMiterlimit="10" strokeWidth="3"/>
                  <path d="M86.66,95.65a4.67,4.67,0,0,1-3.31-1.37l-6.71-6.72A4.67,4.67,0,1,1,83.25,81L90,87.66a4.68,4.68,0,0,1-3.3,8Z" transform="translate(-2.46 -17.46)" stroke="#000" strokeMiterlimit="10" strokeWidth="3"/>
                </g>
              </svg>
              
              ,
                // subNav: [
                //   {
                //     title: 'Teams',
                //     itemId: '/management/teams',
                //   },
                // ],
              },
              {
                title: '(Trade با تتر) معاملات ارز',
                itemId: '/dashboard/teter-Trade',
                elemBefore: () =>  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.84 102.69">
                <g id="HandShake">
                  <path d="M63.8,118.65a4.64,4.64,0,0,1-3.33-1.39L5.31,61.31a4.67,4.67,0,0,1-.08-6.48l32.33-34.4a4.68,4.68,0,0,1,6.5-.3L67.94,41.27a4.68,4.68,0,1,1-6.21,7L41.26,30.15,15.13,58l52,52.74a4.68,4.68,0,0,1-3.33,8Z" transform="translate(-2.46 -17.46)" stroke="#000" strokeMiterlimit="10" strokeWidth="3"/>
                  <path d="M64,118.65a4.68,4.68,0,0,1-3.33-8l52-52.74-26-27.66L68.08,48.14a4.68,4.68,0,1,1-6.49-6.74l22-21.14A4.74,4.74,0,0,1,86.93,19a4.68,4.68,0,0,1,3.29,1.47l32.32,34.4a4.67,4.67,0,0,1-.08,6.48l-55.15,56A4.66,4.66,0,0,1,64,118.65Z" transform="translate(-2.46 -17.46)" stroke="#000" strokeMiterlimit="10" strokeWidth="3"/>
                  <path d="M97,85.14a4.63,4.63,0,0,1-3.31-1.37L75.42,65.51,63,77.89a4.67,4.67,0,0,1-6.61,0L44.49,66a4.67,4.67,0,0,1,0-6.61l39-39a4.68,4.68,0,1,1,6.62,6.61L54.41,62.65,59.73,68,72.11,55.59a4.68,4.68,0,0,1,6.62,0l21.56,21.57a4.68,4.68,0,0,1-3.3,8Z" transform="translate(-2.46 -17.46)" stroke="#000" strokeMiterlimit="10" strokeWidth="3"/>
                  <path d="M76.17,106.28a4.62,4.62,0,0,1-3.3-1.37l-8-8a4.68,4.68,0,0,1,6.61-6.62l8,8a4.68,4.68,0,0,1-3.31,8Z" transform="translate(-2.46 -17.46)" stroke="#000" strokeMiterlimit="10" strokeWidth="3"/>
                  <path d="M86.66,95.65a4.67,4.67,0,0,1-3.31-1.37l-6.71-6.72A4.67,4.67,0,1,1,83.25,81L90,87.66a4.68,4.68,0,0,1-3.3,8Z" transform="translate(-2.46 -17.46)" stroke="#000" strokeMiterlimit="10" strokeWidth="3"/>
                </g>
              </svg>
              ,
                // subNav: [
                //   {
                //     title: 'Teams',
                //     itemId: '/management/teams',
                //   },
                // ],
              },
              {
                title: '(Spot) خرید و فروش ارز',
                itemId: '/dashboard/spot',
                elemBefore: () =>  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85.98 87.62">
                <g id="Refresh">
                  <g>
                    <path d="M25.34,56.81c9.28-26.46,35.43-41.5,64.13-20.19" transform="translate(-20.63 -19.87)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
                    <polygon points="52.01 32.97 82.26 32.97 82.26 0 52.01 32.97"/>
                  </g>
                  <g>
                    <path d="M101.89,70.54C92.61,97,66.46,112,37.76,90.73" transform="translate(-20.63 -19.87)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="10"/>
                    <polygon points="33.97 54.65 3.72 54.65 3.72 87.62 33.97 54.65"/>
                  </g>
                </g>
              </svg>
              
                
                
                // subNav: [
                //   {
                //     title: 'Teams',
                //     itemId: '/management/teams',
                //   },
                // ],
              },
              {
                title: '(Exchange) تبدیل ارز',
                itemId: '/dashboard/exchange',
                elemBefore: () =>  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88.89 96.93">
                <g id="Exchange">
                  <g>
                    <line x1="44.21" y1="75.46" x2="44.21" y2="41.13" fill="none" stroke="#1E4282" strokeMiterlimit="10" strokeWidth="9"/>
                    <line x1="61.27" y1="44.7" x2="27.39" y2="45.29" fill="none" stroke="#1E4282" strokeMiterlimit="10" strokeWidth="9"/>
                    <line x1="60.94" y1="60.15" x2="27.07" y2="60.74" fill="none" stroke="#1E4282" strokeMiterlimit="10" strokeWidth="9"/>
                    <line x1="59.93" y1="27.07" x2="46.78" y2="43.01" fill="none" stroke="#1E4282" strokeMiterlimit="10" strokeWidth="9"/>
                    <line x1="29.37" y1="27.68" x2="42.52" y2="43.62" fill="none" stroke="#1E4282" strokeMiterlimit="10" strokeWidth="9"/>
                  </g>
                  <g>
                    <path d="M24.71,44c17.85-33.16,60.13-31.23,78.05-.91" transform="translate(-19.17 -15.21)" fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="9"/>
                    <line x1="84.39" y1="40.08" x2="84.39" y2="8.49" fill="#fff" stroke="#1E4282" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="9"/>
                  </g>
                  <g>
                    <path d="M102.53,83.38c-17.85,33.16-60.14,31.23-78.06.91" transform="translate(-19.17 -15.21)" fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="9"/>
                    <line x1="4.5" y1="54.75" x2="4.5" y2="88.26" fill="#fff" stroke="#1E4282" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="9"/>
                  </g>
                </g>
              </svg>
              ,
                // subNav: [
                //   {
                //     title: 'Teams',
                //     itemId: '/management/teams',
                //   },
                // ],
              },
              {
                title: '(Open Orders) سفارشات باز',
                itemId: '/dashboard/open-orders',
                elemBefore: () =>  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64.74 78.53">
                <g>
                  <polyline points="36.58 31.56 22.64 46.41 39.98 46.41 27.27 59.94" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="7"/>
                  <g>
                    <polygon points="46.63 10.38 46.63 17.13 17.44 17.13 17.13 17.13 17.13 10.38 3.5 10.38 3.5 75.03 61.24 75.03 61.24 10.38 46.63 10.38" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="7"/>
                    <polygon points="46.63 10.38 46.63 3.5 17.37 3.5 17.44 17.13 46.63 17.13 46.63 10.38" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="7"/>
                  </g>
                </g>
              </svg>
              ,
                // subNav: [
                //   {
                //     title: 'Teams',
                //     itemId: '/management/teams',
                //   },
                // ],
              },
              {
                title: '(History) تاریخچه',
                itemId: '/dashboard/orders-history',
                elemBefore: () => <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101.67 116.54">
                <g id="Timer">
                  <circle cx="50.1" cy="66.44" r="44.1" fill="none" stroke="#1E4282" strokeMiterlimit="10" strokeWidth="12"/>
                  <line x1="50.78" y1="67.87" x2="50.78" y2="35.66" fill="none" stroke="#1E4282" strokeMiterlimit="10" strokeWidth="12"/>
                  <line x1="66.66" y1="6" x2="33.54" y2="6" fill="none" stroke="#1E4282" strokeMiterlimit="10" strokeWidth="12"/>
                  <line x1="97.35" y1="31.56" x2="87.16" y2="21" fill="none" stroke="#1E4282" strokeMiterlimit="10" strokeWidth="12"/>
                </g>
              </svg>
              
              
              ,
                subNav: [
                    {
                        title: 'سفارش ها',
                        itemId: '/dashboard/orders',
                        elemBefore: () =>  <ListAltIcon  className="SidebarIcon" />,
                        
                        },
                    {
                        title: 'معاملات',
                        itemId: '/dashboard/deals',
                        elemBefore: () =>  <TransformIcon  className="SidebarIcon" />,
                        
                        },
                    {
                        title: 'تراکنش ها',
                        itemId: '/dashboard/transactions-history',
                        elemBefore: () =>  <ReceiptIcon  className="SidebarIcon" />,
                        
                        },
                    {
                        title: 'واریز ها',
                        itemId: '/dashboard/deposit-history',
                        elemBefore: () =>  <MonetizationOnIcon  className="SidebarIcon" />,
                        
                        },
                    {
                        title: 'برداشت ها',
                        itemId: '/dashboard/withdraw',
                        elemBefore: () =>  <MergeTypeIcon  className="SidebarIcon" />,
                        
                        },
                ],
              },
              {
                title: '(Security) امنیت',
                itemId: '/dashboard/security',
                elemBefore: () =>  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75.71 87.43">
                <g id="Security">
                  <path d="M64,102.39c13.39-8.58,25.35-17.67,32.43-28.48V33.35C85.27,28.08,74.39,24.83,64,25" transform="translate(-25.76 -19.96)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="10"/>
                  <path d="M63.19,102.39C49.81,93.81,37.84,84.72,30.76,73.91V33.35C42,28.08,52.85,24.83,63.19,25" transform="translate(-25.76 -19.96)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="10"/>
                </g>
              </svg>
              ,
                subNav: [
                  {
                    title: 'وضعیت امنیتی',
                    itemId: '/dashboard/security-status',
                    elemBefore: () =>  <SecurityIcon  className="SidebarIcon" />,
                    
                  },
                  {
                    title: 'تاریخچه لاگین',
                    itemId: '/dashboard/login-history',
                    elemBefore: () =>  <SettingsBackupRestoreIcon  className="SidebarIcon" />,
                  },
                ],
              },
              {
                title: '(Refferal Code) دعوت دوستان',
                itemId: '/dashboard/invite-friends',
                elemBefore: () =>  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70.37 69.58">
                <g id="Refferal">
                  <circle cx="9.72" cy="9.5" r="6.5" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6"/>
                  <circle cx="35.07" cy="9.5" r="6.5" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6"/>
                  <circle cx="60.88" cy="9.5" r="6.5" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6"/>
                  <circle cx="9.5" cy="34.79" r="6.5" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6"/>
                  <circle cx="34.85" cy="34.79" r="6.5" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6"/>
                  <circle cx="60.65" cy="34.79" r="6.5" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6"/>
                  <circle cx="9.5" cy="60.08" r="6.5" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6"/>
                  <circle cx="34.85" cy="60.08" r="6.5" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6"/>
                  <circle cx="60.65" cy="60.08" r="6.5" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="6"/>
                  <line x1="56.53" y1="39.81" x2="40.15" y2="56.19" fill="none" stroke="#1E4282" strokeMiterlimit="10" strokeWidth="6"/>
                  <line x1="55.61" y1="13.29" x2="39.06" y2="29.84" fill="none" stroke="#1E4282" strokeMiterlimit="10" strokeWidth="6"/>
                  <line x1="30.23" y1="39.35" x2="14.04" y2="55.54" fill="none" stroke="#1E4282" strokeMiterlimit="10" strokeWidth="6"/>
                  <line x1="30.23" y1="13.81" x2="13.97" y2="30.08" fill="none" stroke="#1E4282" strokeMiterlimit="10" strokeWidth="6"/>
                </g>
              </svg>
              ,
                // subNav: [
                //   {
                //     title: 'Teams',
                //     itemId: '/management/teams',
                //   },
                // ],
              },
              {
                title: '(Support) پشتیبانی',
                itemId: '/dashboard/support',
                elemBefore: () =>  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80.47 80.9"><g id="Support"><polygon points="21.72 58.11 76.47 58.11 76.47 4 4 4 4 76.9 21.72 58.11" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="8"/><polyline points="24.76 29.4 36.1 40.75 54.78 22.07" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="6"/></g></svg>,
                // subNav: [
                //   {
                //     title: 'Teams',
                //     itemId: '/management/teams',
                //   },
                // ],
              },
            ]}
          />
        </div>
    )
}

export default Sidebar
