import React from "react";
import UseAnimations from "react-useanimations";
import github from 'react-useanimations/lib/github'

const Animation = () => {
  return (
    <div>
      <UseAnimations animation={github} size={56} wrapperStyle={{ padding: 100 }} />
    </div>
  );
};

export default Animation
