import React from 'react'

const LoginHistory = () => {

    const data = [
        {ID : '1' , Date : '10 اردیبهشت' , Ip : '192.168.0.1' , BrowserId : 'Firefox(fkldjgdfkh32897fhueghddfg8047cynt73)' , Status : 'موفق' },
        {ID : '2' , Date : '10 اردیبهشت' , Ip : '192.168.0.1' , BrowserId : 'Firefox(fkldjgdfkh32897fhueghddfg8047cynt73)' , Status : 'نا موفق' },
    ]

    return (
        <div className="LoginHistoryWrapper">
            <div className="LoginHistory ThemeBorder ThemeShadow ThemeLayer1">
                <div className="LoginHistoryHeader ThemeBorderBottom">
                    <span className="LoginHistoryHeaderTitle ThemeText2">تاریخچه لاگین (ورود شما به حساب کاربری)</span>
                    </div>
                <div className="TableWrapper">
                <table>
                    <thead>
                        <tr>
                            <th>تاریخ</th>
                            <th>آدرس IP</th>
                            <th>شناسه مرورگر</th>
                            <th>وضعیت</th>
                        </tr>
                    </thead>
                    {/* <tr>
                        <td className="ThemeText2"><CreditCardIcon />3922-8765-8765</td>
                        <td className="ThemeText2">3922-8765-8765-75648</td>
                        <td className="ThemeText2">34178400000394756387</td>
                        <td className="ThemeText3">در صف بررسی</td>
                        <td><DeleteIcon /><CreateIcon /></td>
                    </tr>
                    <tr>
                        <td className="ThemeText2"><CreditCardIcon />3922-8765-8765</td>
                        <td className="ThemeText2">3922-8765-8765-75648</td>
                        <td className="ThemeText2">34178400000394756387</td>
                        <td className="ThemeText3">در صف بررسی</td>
                        <td><DeleteIcon /><CreateIcon /></td>
                    </tr> */}
                    <tbody>
                    {data.map((user) => (
                        <tr key={user.ID} id={user.ID}>
                            <td className="ThemeText2">{user.Date}</td>
                            <td className="ThemeText2">{user.Ip}</td>
                            <td className="ThemeText3">{user.BrowserId}</td>
                            <td className="ThemeText2">{user.Status}</td>
                        </tr>
                        ))}
                    </tbody>
                </table>
                </div>
            </div>
        </div>
    )
}

export default LoginHistory
