import React from 'react'
import HeaderButton from '../GlobalComponents/HeaderButton';
import PanelHeader from '../GlobalComponents/PanelHeader';
//import Material Ui Icons
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
 

const Transactions = () => {

    const data = [
        {ID : '1' , Market : 'تتر/تومان' , Type : 'سریع' , SellBuy : 'خرید' , Price : '24,200' , Count : '152' , TotalPrice : '3,291,000' , Status : 'انجام شده' , Date : '1400/01/23' , CryptoIcon: 'https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@d5c68edec1f5eaec59ac77ff2b48144679cebca1/svg/icon/btc.svg'},
        {ID : '2' , Market : 'تتر/تومان' , Type : 'سریع' , SellBuy : 'فروش' , Price : '32,000' , Count : '145' , TotalPrice : '8,291,000' , Status : 'انجام شده' , Date : '1400/01/23' , CryptoIcon: 'https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@d5c68edec1f5eaec59ac77ff2b48144679cebca1/svg/icon/eth.svg'},
    ]

    return (
        <div className="OrdersWrapper">
            <div className="Orders ThemeBorder ThemeShadow ThemeLayer3">
                    <PanelHeader Title="تاریخچه تراکنش ها" Action1={
                        <HeaderButton Name="کیف پول های من" LinkAddress="/dashboard/wallet" Icon={<AccountBalanceWalletIcon />} />
                        } />
               <div className="TableWrapper">
               <table>
                    <thead>
                        <tr>
                            <th>مارکت (بازار)</th>
                            <th>نوع</th>
                            <th>خرید/فروش</th>
                            <th>قیمت</th>
                            <th>مقدار</th>
                            <th>مبلغ کل</th>
                            <th>وضعیت</th>
                            <th>تاریخ</th>
                        </tr>
                    </thead>
                    {/* <tr>
                        <td className="ThemeText2"><CreditCardIcon />3922-8765-8765</td>
                        <td className="ThemeText2">3922-8765-8765-75648</td>
                        <td className="ThemeText2">34178400000394756387</td>
                        <td className="ThemeText3">در صف بررسی</td>
                        <td><DeleteIcon /><CreateIcon /></td>
                    </tr>
                    <tr>
                        <td className="ThemeText2"><CreditCardIcon />3922-8765-8765</td>
                        <td className="ThemeText2">3922-8765-8765-75648</td>
                        <td className="ThemeText2">34178400000394756387</td>
                        <td className="ThemeText3">در صف بررسی</td>
                        <td><DeleteIcon /><CreateIcon /></td>
                    </tr> */}
                    <tbody>
                    {data.map((user) => (
                        <tr key={user.ID} id={user.ID}>
                            <td className="ThemeText2"><img src={user.CryptoIcon} alt="" />{user.Market}</td>
                            <td className="ThemeText2">{user.Type}</td>
                            <td className="ThemeText3">{user.SellBuy}</td>
                            <td className="ThemeText2">{user.Price}</td>
                            <td className="ThemeText2">{user.Count}</td>
                            <td className="ThemeText2">{user.TotalPrice}</td>
                            <td className="ThemeText2">{user.Status}</td>
                            <td className="ThemeText2">{user.Date}</td>
                        </tr>
                        ))}
                    </tbody>
                </table>
               </div>
            </div>
        </div>
    )
}

export default Transactions
