import React, {useRef , useEffect} from 'react';
import Notification from '../../Notification';
import {Power1 , gsap} from 'gsap';
import { CSSPlugin } from 'gsap/CSSPlugin'
import PanelHeader from '../../GlobalComponents/PanelHeader';
// import axios
import axios from 'axios';

const Dashboard = ({ Loading }) => {

    gsap.registerPlugin(CSSPlugin)

    let app = useRef(null);
    
    let tl = new gsap.timeline({repeat:0})
    

    React.useEffect(() => {

        tl.from(app , 1.2 , {x:-100 , opacity : 0 , ease : Power1.easeOut , overwrite: false})  
        
        setTimeout(
            () => Loading (false), 
            3000
          );

    }, [])

    React.useEffect(() => {
        
        setTimeout(
            () => Loading (false), 
            3000
          );

    })

    
    const data = [
        {ID : '1' , Market : 'تتر/تومان' , Price : '24,200' , Type : 'IRR' , Link : '23K8X41'  , Status : 'تایید شده' , Date : '1400/01/23' , CryptoIcon: 'https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@d5c68edec1f5eaec59ac77ff2b48144679cebca1/svg/icon/btc.svg'},
        {ID : '2' , Market : 'تتر/تومان' , Price : '32,000' , Type : 'TRC20' , Link : '23K8X41'  , Status : 'تایید شده' , Date : '1400/01/23' , CryptoIcon: 'https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@d5c68edec1f5eaec59ac77ff2b48144679cebca1/svg/icon/eth.svg'},
    ]

    function GetDataHandler() {

        axios.get(`http://localhost:5432/PayPoool-api/`)
        .then(res => {
        const persons = res.data;
        console.log(persons);
        })
        
    }

    return (
        <React.Fragment>
             <Notification MainText="در نظر داشته باشید که توکن ها پس از تعدادی تایید شبکه بلاکچین واریز خواهند شد. وضعیت واریز را از قسمت تاریخچه واریزها پیگیری نمایید." SubText="اطلاعیه:"/>
            
            {/* <button onClick={GetDataHandler}>Get data from php api</button> */}
            <div className="DashboardWrapper">
            <div className="DashboardUl">
                <div className="DashboardUlWrapper">
                    <ul ref={el => app = el}>
                    <li className="ThemeLayer2 ThemeShadow ThemeBorder">
                        <img src="https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@d5c68edec1f5eaec59ac77ff2b48144679cebca1/svg/icon/link.svg" alt="" className="MainItemsIcon" />
                        <h3 className="MainItemsName ThemeText2">چین لینک (LINK)</h3>
                        <div className="Price ThemeLayer3 ThemeBorder ThemeShadow">
                            <span className="PriceOff">14%</span>
                            <span className="PriceText">تومان</span>
                            <span className="PriceNumber ThemeText2">924,495,000</span>
                        </div>
                        <div className="Price ThemeLayer3 ThemeBorder ThemeShadow">
                            <span className="PriceText">تومان</span>
                            <span className="PriceNumber-min ThemeText2">924,495,000</span>
                            <span className="MinMax">حداقل</span>
                        </div>
                        <div className="Price ThemeLayer3 ThemeBorder ThemeShadow">
                            <span className="PriceText">تومان</span>
                            <span className="PriceNumber-min ThemeText2">924,495,000</span>
                            <span className="MinMax">حداکثر</span>
                        </div>
                    </li>
                    <li className="ThemeLayer2 ThemeShadow ThemeBorder">
                        <img src="https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@d5c68edec1f5eaec59ac77ff2b48144679cebca1/svg/icon/ltc.svg" alt="" className="MainItemsIcon" />
                        <h3 className="MainItemsName ThemeText2">لایت کوین (LTC)</h3>
                        <div className="Price ThemeLayer3 ThemeBorder ThemeShadow">
                            <span className="PriceOff">14%</span>
                            <span className="PriceText">تومان</span>
                            <span className="PriceNumber ThemeText2">924,495,000</span>
                        </div>
                        <div className="Price ThemeLayer3 ThemeBorder ThemeShadow">
                            <span className="PriceText">تومان</span>
                            <span className="PriceNumber-min ThemeText2">924,495,000</span>
                            <span className="MinMax">حداقل</span>
                        </div>
                        <div className="Price ThemeLayer3 ThemeBorder ThemeShadow">
                            <span className="PriceText">تومان</span>
                            <span className="PriceNumber-min ThemeText2">924,495,000</span>
                            <span className="MinMax">حداکثر</span>
                        </div>
                    </li>
                    <li className="ThemeLayer2 ThemeShadow ThemeBorder">
                        <img src="https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@d5c68edec1f5eaec59ac77ff2b48144679cebca1/svg/icon/bch.svg" alt="" className="MainItemsIcon" />
                        <h3 className="MainItemsName ThemeText2">بیت کوین کش (BCH)</h3>
                        <div className="Price ThemeLayer3 ThemeBorder ThemeShadow">
                            <span className="PriceOff">14%</span>
                            <span className="PriceText">تومان</span>
                            <span className="PriceNumber ThemeText2">924,495,000</span>
                        </div>
                        <div className="Price ThemeLayer3 ThemeBorder ThemeShadow">
                            <span className="PriceText">تومان</span>
                            <span className="PriceNumber-min ThemeText2">924,495,000</span>
                            <span className="MinMax">حداقل</span>
                        </div>
                        <div className="Price ThemeLayer3 ThemeBorder ThemeShadow">
                            <span className="PriceText">تومان</span>
                            <span className="PriceNumber-min ThemeText2">924,495,000</span>
                            <span className="MinMax">حداکثر</span>
                        </div>
                    </li>
                    <li className="ThemeLayer2 ThemeShadow ThemeBorder">
                        <img src="https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@d5c68edec1f5eaec59ac77ff2b48144679cebca1/svg/icon/xlm.svg" alt="" className="MainItemsIcon" />
                        <h3 className="MainItemsName ThemeText2">استلار (XLM)</h3>
                        <div className="Price ThemeLayer3 ThemeBorder ThemeShadow">
                            <span className="PriceOff">14%</span>
                            <span className="PriceText">تومان</span>
                            <span className="PriceNumber ThemeText2">924,495,000</span>
                        </div>
                        <div className="Price ThemeLayer3 ThemeBorder ThemeShadow">
                            <span className="PriceText">تومان</span>
                            <span className="PriceNumber-min ThemeText2">924,495,000</span>
                            <span className="MinMax">حداقل</span>
                        </div>
                        <div className="Price ThemeLayer3 ThemeBorder ThemeShadow">
                            <span className="PriceText">تومان</span>
                            <span className="PriceNumber-min ThemeText2">924,495,000</span>
                            <span className="MinMax">حداکثر</span>
                        </div>
                    </li>
                    <li className="ThemeLayer2 ThemeShadow ThemeBorder">
                        <img src="https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@d5c68edec1f5eaec59ac77ff2b48144679cebca1/svg/icon/bnb.svg" alt="" className="MainItemsIcon" />
                        <h3 className="MainItemsName ThemeText2">بایننس کوین (BNB)</h3>
                        <div className="Price ThemeLayer3 ThemeBorder ThemeShadow">
                            <span className="PriceOff">14%</span>
                            <span className="PriceText">تومان</span>
                            <span className="PriceNumber ThemeText2">924,495,000</span>
                        </div>
                        <div className="Price ThemeLayer3 ThemeBorder ThemeShadow">
                            <span className="PriceText">تومان</span>
                            <span className="PriceNumber-min ThemeText2">924,495,000</span>
                            <span className="MinMax">حداقل</span>
                        </div>
                        <div className="Price ThemeLayer3 ThemeBorder ThemeShadow">
                            <span className="PriceText">تومان</span>
                            <span className="PriceNumber-min ThemeText2">924,495,000</span>
                            <span className="MinMax">حداکثر</span>
                        </div>
                    </li>
                    <li className="ThemeLayer2 ThemeShadow ThemeBorder">
                        <img src="https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@d5c68edec1f5eaec59ac77ff2b48144679cebca1/svg/icon/doge.svg" alt="" className="MainItemsIcon" />
                        <h3 className="MainItemsName ThemeText2">دوج کوین (DOGE)</h3>
                        <div className="Price ThemeLayer3 ThemeBorder ThemeShadow">
                            <span className="PriceOff">14%</span>
                            <span className="PriceText">تومان</span>
                            <span className="PriceNumber ThemeText2">924,495,000</span>
                        </div>
                        <div className="Price ThemeLayer3 ThemeBorder ThemeShadow">
                            <span className="PriceText">تومان</span>
                            <span className="PriceNumber-min ThemeText2">924,495,000</span>
                            <span className="MinMax">حداقل</span>
                        </div>
                        <div className="Price ThemeLayer3 ThemeBorder ThemeShadow">
                            <span className="PriceText">تومان</span>
                            <span className="PriceNumber-min ThemeText2">924,495,000</span>
                            <span className="MinMax">حداکثر</span>
                        </div>
                    </li>
                     <li className="ThemeLayer2 ThemeShadow ThemeShadow ThemeBorder">
                        <img src="https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@d5c68edec1f5eaec59ac77ff2b48144679cebca1/svg/icon/btc.svg" alt="" className="MainItemsIcon" />
                        <h3 className="MainItemsName ThemeText2">بیت کوین (BTC)</h3>
                        <div className="Price ThemeLayer3 ThemeBorder ThemeShadow">
                            <span className="PriceOff">14%</span>
                            <span className="PriceText">تومان</span>
                            <span className="PriceNumber ThemeText2">924,495,000</span>
                        </div>
                        <div className="Price ThemeLayer3 ThemeBorder ThemeShadow">
                            <span className="PriceText">تومان</span>
                            <span className="PriceNumber-min ThemeText2">924,495,000</span>
                            <span className="MinMax">حداقل</span>
                        </div>
                        <div className="Price ThemeLayer3 ThemeBorder ThemeShadow">
                            <span className="PriceText">تومان</span>
                            <span className="PriceNumber-min ThemeText2">924,495,000</span>
                            <span className="MinMax">حداکثر</span>
                        </div>
                    </li>
                    <li className="ThemeLayer2 ThemeShadow ThemeBorder">
                        <img src="https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@d5c68edec1f5eaec59ac77ff2b48144679cebca1/svg/icon/eth.svg" alt="" className="MainItemsIcon" />
                        <h3 className="MainItemsName ThemeText2">اتریوم (ETH)</h3>
                        <div className="Price ThemeLayer3 ThemeBorder ThemeShadow">
                            <span className="PriceOff">14%</span>
                            <span className="PriceText">تومان</span>
                            <span className="PriceNumber ThemeText2">924,495,000</span>
                        </div>
                        <div className="Price ThemeLayer3 ThemeBorder ThemeShadow">
                            <span className="PriceText">تومان</span>
                            <span className="PriceNumber-min ThemeText2">924,495,000</span>
                            <span className="MinMax">حداقل</span>
                        </div>
                        <div className="Price ThemeLayer3 ThemeBorder ThemeShadow">
                            <span className="PriceText">تومان</span>
                            <span className="PriceNumber-min ThemeText2">924,495,000</span>
                            <span className="MinMax">حداکثر</span>
                        </div>
                    </li>
                    <li className="ThemeLayer2 ThemeShadow ThemeBorder">
                        <img src="https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@d5c68edec1f5eaec59ac77ff2b48144679cebca1/svg/icon/usdt.svg" alt="" className="MainItemsIcon" />
                        <h3 className="MainItemsName ThemeText2">تتر (USDT)</h3>
                        <div className="Price ThemeLayer3 ThemeBorder ThemeShadow">
                            <span className="PriceOff">14%</span>
                            <span className="PriceText">تومان</span>
                            <span className="PriceNumber ThemeText2">924,495,000</span>
                        </div>
                        <div className="Price ThemeLayer3 ThemeBorder ThemeShadow">
                            <span className="PriceText">تومان</span>
                            <span className="PriceNumber-min ThemeText2">924,495,000</span>
                            <span className="MinMax">حداقل</span>
                        </div>
                        <div className="Price ThemeLayer3 ThemeBorder ThemeShadow">
                            <span className="PriceText">تومان</span>
                            <span className="PriceNumber-min ThemeText2">924,495,000</span>
                            <span className="MinMax">حداکثر</span>
                        </div>
                    </li>
                    <li className="ThemeLayer2 ThemeShadow ThemeBorder">
                        <img src="https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@d5c68edec1f5eaec59ac77ff2b48144679cebca1/svg/icon/xrp.svg" alt="" className="MainItemsIcon" />
                        <h3 className="MainItemsName ThemeText2">ریپل (XRP)</h3>
                        <div className="Price ThemeLayer3 ThemeBorder ThemeShadow">
                            <span className="PriceOff">14%</span>
                            <span className="PriceText">تومان</span>
                            <span className="PriceNumber ThemeText2">924,495,000</span>
                        </div>
                        <div className="Price ThemeLayer3 ThemeBorder ThemeShadow">
                            <span className="PriceText">تومان</span>
                            <span className="PriceNumber-min ThemeText2">924,495,000</span>
                            <span className="MinMax">حداقل</span>
                        </div>
                        <div className="Price ThemeLayer3 ThemeBorder ThemeShadow">
                            <span className="PriceText">تومان</span>
                            <span className="PriceNumber-min ThemeText2">924,495,000</span>
                            <span className="MinMax">حداکثر</span>
                        </div>
                    </li>
                    <li className="ThemeLayer2 ThemeShadow ThemeBorder">
                        <img src="https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@d5c68edec1f5eaec59ac77ff2b48144679cebca1/svg/icon/dot.svg" alt="" className="MainItemsIcon" />
                        <h3 className="MainItemsName ThemeText2">پولکادات (DOT)</h3>
                        <div className="Price ThemeLayer3 ThemeBorder ThemeShadow">
                            <span className="PriceOff">14%</span>
                            <span className="PriceText">تومان</span>
                            <span className="PriceNumber ThemeText2">924,495,000</span>
                        </div>
                        <div className="Price ThemeLayer3 ThemeBorder ThemeShadow">
                            <span className="PriceText">تومان</span>
                            <span className="PriceNumber-min ThemeText2">924,495,000</span>
                            <span className="MinMax">حداقل</span>
                        </div>
                        <div className="Price ThemeLayer3 ThemeBorder ThemeShadow">
                            <span className="PriceText">تومان</span>
                            <span className="PriceNumber-min ThemeText2">924,495,000</span>
                            <span className="MinMax">حداکثر</span>
                        </div>
                    </li>
                    <li className="ThemeLayer2 ThemeShadow ThemeBorder">
                        <img src="https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@d5c68edec1f5eaec59ac77ff2b48144679cebca1/svg/icon/ada.svg" alt="" className="MainItemsIcon" />
                        <h3 className="MainItemsName ThemeText2">کاردانو (ADA)</h3>
                        <div className="Price ThemeLayer3 ThemeBorder ThemeShadow">
                            <span className="PriceOff">14%</span>
                            <span className="PriceText">تومان</span>
                            <span className="PriceNumber ThemeText2">924,495,000</span>
                        </div>
                        <div className="Price ThemeLayer3 ThemeBorder ThemeShadow">
                            <span className="PriceText">تومان</span>
                            <span className="PriceNumber-min ThemeText2">924,495,000</span>
                            <span className="MinMax">حداقل</span>
                        </div>
                        <div className="Price ThemeLayer3 ThemeBorder ThemeShadow">
                            <span className="PriceText">تومان</span>
                            <span className="PriceNumber-min ThemeText2">924,495,000</span>
                            <span className="MinMax">حداکثر</span>
                        </div>
                    </li> 
                </ul> 
                </div>
                </div>

                <div className="DashboardAccountInformation ThemeBorder ThemeShadow ThemeLayer3">
                <PanelHeader Title="مشخصات حساب کاربری" />
                <ul>
                    <li><span>سطح کاربری:</span> <span>VIP1 تایید شده سطح</span></li>
                    <li><span className="DashboardAccountInformationUpgrade">ارتقای سطح کاربری</span></li>
                    <li><span>مجموع برداشت روزانه:</span> <span>0 از 10,000,000 تومان</span></li>
                    <li><span>ارزش معاملات یک ماه:</span> <span>0 تومان (مرحله بعد 10 میلیون تومان)</span></li>
                    <li><span>برداشت روزانه رمز ارز:</span> <span>0 از 10,000,000 تومان</span></li>
                    <li><span>مجموع روزانه ماهانه:</span> <span>0 از 15,000,000 تومان</span></li>
                    <li><span>ارزش معاملات یک ماه:</span> <span>0 از 20,000,000 تومان</span></li>
                    <li><span>کارمزد مطالعات:</span> <span>0.35% (مرحله بعد 0.3%)</span></li>

                </ul>
                </div>

                <div className="Orders ThemeBorder ThemeShadow ThemeLayer3">
            <PanelHeader Title="سفارشات باز شما"  />
                <div className="TableWrapper">
                <table>
                    <thead>
                        <tr>
                            <th>توکن (ارز)</th>
                            <th>مقدار</th>
                            <th>نوع</th>
                            <th>شماره پیگیری/لینک تراکنش</th>
                            <th>وضعیت</th>
                            <th>تاریخ</th>
                        </tr>
                    </thead>
                    {/* <tr>
                        <td className="ThemeText2"><CreditCardIcon />3922-8765-8765</td>
                        <td className="ThemeText2">3922-8765-8765-75648</td>
                        <td className="ThemeText2">34178400000394756387</td>
                        <td className="ThemeText3">در صف بررسی</td>
                        <td><DeleteIcon /><CreateIcon /></td>
                    </tr>
                    <tr>
                        <td className="ThemeText2"><CreditCardIcon />3922-8765-8765</td>
                        <td className="ThemeText2">3922-8765-8765-75648</td>
                        <td className="ThemeText2">34178400000394756387</td>
                        <td className="ThemeText3">در صف بررسی</td>
                        <td><DeleteIcon /><CreateIcon /></td>
                    </tr> */}
                    <tbody>
                    {data.map((user) => (
                        <tr key={user.ID} id={user.ID}>
                            <td className="ThemeText2"><img src={user.CryptoIcon} alt="" />{user.Market}</td>
                            <td className="ThemeText2">{user.Price}</td>
                            <td className="ThemeText2">{user.Type}</td>
                            <td className="ThemeText2">{user.Link}</td>
                            <td className="ThemeText3">{user.Status}</td>
                            <td className="ThemeText2">{user.Date}</td>
                        </tr>
                        ))}
                    </tbody>
                </table>
                </div>
            </div>

            <div className="Orders ThemeBorder ThemeShadow ThemeLayer3">
            <PanelHeader Title="معاملات اخیر شما"  />
                <div className="TableWrapper">
                <table>
                    <thead>
                        <tr>
                            <th>توکن (ارز)</th>
                            <th>مقدار</th>
                            <th>نوع</th>
                            <th>شماره پیگیری/لینک تراکنش</th>
                            <th>وضعیت</th>
                            <th>تاریخ</th>
                        </tr>
                    </thead>
                    {/* <tr>
                        <td className="ThemeText2"><CreditCardIcon />3922-8765-8765</td>
                        <td className="ThemeText2">3922-8765-8765-75648</td>
                        <td className="ThemeText2">34178400000394756387</td>
                        <td className="ThemeText3">در صف بررسی</td>
                        <td><DeleteIcon /><CreateIcon /></td>
                    </tr>
                    <tr>
                        <td className="ThemeText2"><CreditCardIcon />3922-8765-8765</td>
                        <td className="ThemeText2">3922-8765-8765-75648</td>
                        <td className="ThemeText2">34178400000394756387</td>
                        <td className="ThemeText3">در صف بررسی</td>
                        <td><DeleteIcon /><CreateIcon /></td>
                    </tr> */}
                    <tbody>
                    {data.map((user) => (
                        <tr key={user.ID} id={user.ID}>
                            <td className="ThemeText2"><img src={user.CryptoIcon} alt="" />{user.Market}</td>
                            <td className="ThemeText2">{user.Price}</td>
                            <td className="ThemeText2">{user.Type}</td>
                            <td className="ThemeText2">{user.Link}</td>
                            <td className="ThemeText3">{user.Status}</td>
                            <td className="ThemeText2">{user.Date}</td>
                        </tr>
                        ))}
                    </tbody>
                </table>
                </div>
            </div>

            </div>
        </React.Fragment>
    )
}

export default Dashboard
