import React from 'react'
//import Router
import {
    Link
} from "react-router-dom";

const HeaderButton = ({Icon,Name,LinkAddress}) => {
    return (
        <React.Fragment>
            <Link to={LinkAddress}><div className="HeaderButton ThemeButton"><span>{Name}</span>{Icon}</div></Link>
        </React.Fragment>
    )
}

export default HeaderButton
