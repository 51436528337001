import React from 'react'
import NotificationWi from './NotificationWi'

const EditProfile = () => {
    return (
        <div className="EditProfileWrapper">
            <NotificationWi MainText=" لطفا قبل از تکمیل اطلاعات پروفایل جهت احراز هویت، موارد زیر را با دقت مطالعه بفرمایید، تا در احراز هویت شما تسریع شود." SubText="کاربر گرامی :" NotifUl=""/>
            <div className="AccountEditWrapper ThemeLayer2 ThemeBorder">
                <div className="AccountEditWrapperHeader">
                    <span className="AccountEditVerificationStatusWrapper"> وضعیت <span className="AccountEditVerificationStatus ThemeLayer3"> تایید شده سطح ۱</span></span>
                    <span className="AccountEditHeaderTitle ThemeText2">ویرایش پروفایل (اطلاعات حساب کاربری)</span>
                </div>
                <ul>
                    <li><input type="text" className="ThemeBorder"/><span>نام</span></li>
                    <li><input type="text" className="ThemeBorder"/><span>نام خانوادگی</span></li>
                    <li><input type="text" className="ThemeBorder"/><span>کدملی</span></li>
                    <li><input type="text" className="ThemeBorder"/><span>شماره موبایل</span></li>
                    <li><input type="text" className="ThemeBorder"/><span>ایمیل</span></li>
                </ul>
                <div className="AccountEditFooter">
                    <div className="AccountEditSubmit"><span>ارسال</span></div>
                    <span className="AccountEditFooterTitle ThemeText4">هر تغییر و ویرایش در مشخصات پروفایل، مستلزم تایید هویت مجدد می باشد</span>
                </div>
            </div>

            {/* <div className="BankEditWrapper ThemeLayer2 ThemeBorder">
                <div className="BankEditWrapperHeader">
                    <span className="BankEditHeaderTitle ThemeText2">ویرایش اطلاعات حساب های بانکی</span>
                </div>
                <ul>
                    <li>
                        <div className="BankEditLi"><input type="text" className="ThemeBorder"/><span className="ThemeText2">شماره حساب</span></div>
                        <div className="BankEditLi"><input type="text" className="ThemeBorder"/><span className="ThemeText2">شماره کارت</span></div>
                        <div className="BankEditLi"><input type="text" className="ThemeBorder"/><span className="ThemeText2">شماره شبا</span></div>
                        <div></div>
                    </li>
                </ul>
            </div> */}
        </div>
    )
}

export default EditProfile
