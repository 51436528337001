import React from 'react'
import HeaderButton from '../GlobalComponents/HeaderButton';
import PanelHeader from '../GlobalComponents/PanelHeader';
//import Material Ui Icons
import AssessmentIcon from '@material-ui/icons/Assessment';
import CachedIcon from '@material-ui/icons/Cached';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SearchIcon from '@material-ui/icons/Search';
//import chart 
import { Doughnut } from 'react-chartjs-2';

const Wallet = () => {

    const state = {
        labels: [],
        datasets: [
          {
            label: 'Rainfall',
            backgroundColor: [
              '#02C09B',
              '#20A9D7',
              
            ],
            hoverBackgroundColor: [
            '#02C09B',
            '#20A9D7',

            ],
            data: [65, 20]
          }
        ]
      }
      

      const data = [
        {ID : '1' , CryptoDetails : 'IRR' , CryptoName : 'ریال' , CryptoTotal : '2568390' , CryptoToman : '33,000' , WorldPrice : '$152' , CryptoIcon: 'https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@d5c68edec1f5eaec59ac77ff2b48144679cebca1/svg/icon/btc.svg' },
        {ID : '2' , CryptoDetails : 'BTC' , CryptoName : 'بیتکوین' , CryptoTotal : '7858390' , CryptoToman : '32,000' , WorldPrice : '$145' , CryptoIcon: 'https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@d5c68edec1f5eaec59ac77ff2b48144679cebca1/svg/icon/eth.svg' },
        {ID : '2' , CryptoDetails : 'BNB' , CryptoName : 'بایننس' , CryptoTotal : '7858390' , CryptoToman : '32,000' , WorldPrice : '$145' , CryptoIcon: 'https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@d5c68edec1f5eaec59ac77ff2b48144679cebca1/svg/icon/bnb.svg' },
        {ID : '2' , CryptoDetails : 'XRP' , CryptoName : 'ریپل' , CryptoTotal : '7858390' , CryptoToman : '32,000' , WorldPrice : '$145' , CryptoIcon: 'https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@d5c68edec1f5eaec59ac77ff2b48144679cebca1/svg/icon/xrp.svg' },
    ]

    return (
        <div className="WalletWrapper">
            <div className="Wallet ThemeBorder ThemeShadow ThemeLayer3">
                
                    <PanelHeader Title="امورمالی" Action1={
                        <HeaderButton Name="تاریخچه دارایی" LinkAddress="/dashboard/wallet" Icon={<AssessmentIcon />} />
                        } />
                    <div className="WalletContainer">
                        <div className="WalletConvertWrapper">
                            <div className="WalletIventoryConvertWrapper">
                                <div className="WalletIventoryConvertWrapperHeader">
                                    <VisibilityIcon />
                                    <span>ارزش تخمینی دارایی ها</span>
                                </div>
                                <div className="WalletIventoryConvertWrapperBody">
                                <div className="ThemeBorder ThemeLayer"><span>تومان</span><input type="text" className="ThemeText5" value="352,239,000"/></div>
                                    <h1>≈</h1>
                                    <div className="ThemeBorder ThemeLayer"><input type="text" className="ThemeText5" value="352,239,000"/><span>USD</span></div>
                                </div>
                            </div>
                            <div className="WalletIventoryMaximumWrapper">
                                <div className="WalletIventoryMaximumWrapperLeft">
                                    <span>سقف مجاز برداشت در ۲۴ ساعت</span><br />
                                    <span>20,000,000 تومان</span>
                                </div>
                                <div className="WalletChart"><Doughnut data={state} options={{title:{display:true,text:'Average Rainfall per month',fontSize:20},legend:{display:true,position:'right'}}} />
                                    <span className="AccountUpgrade ThemeText3">ارتقای سطح کاربری</span>
                                </div>
                                <div className="WalletIventoryMaximumWrapperRight">
                                    <span>مبلغ برداشت در ۲۴ ساعت</span><br />
                                    <span>0 تومان</span>
                                </div>
                            </div>
                        </div>
                        <div className="WalletTableSearchWallet">
                            <div className="WalletTableSearchWrapper ThemeLayer3 ThemeBorder">
                                <SearchIcon />
                                <input type="text" className="ThemeLayer3" placeholder="نام ارز را وارد کنید" />
                            </div>
                        </div>
                    </div>
                <div className="TableWrapper">
                <table>
                    <thead>
                        <tr>
                            <th>مشخصات ارز</th>
                            <th>نام ارز</th>
                            <th>موجودی ارز</th>
                            <th>موجودی به تومان</th>
                            <th></th>
                            <th>قیمت جهانی</th>
                            <th></th>
                            <th></th>
                            <th>دپوزیت</th>
                            <th>برداشت</th>
                            <th>مبادله</th>
                            <th><CachedIcon /></th>
                        </tr>
                    </thead>
                    {/* <tr>
                        <td className="ThemeText2"><CreditCardIcon />3922-8765-8765</td>
                        <td className="ThemeText2">3922-8765-8765-75648</td>
                        <td className="ThemeText2">34178400000394756387</td>
                        <td className="ThemeText3">در صف بررسی</td>
                        <td><DeleteIcon /><CreateIcon /></td>
                    </tr>
                    <tr>
                        <td className="ThemeText2"><CreditCardIcon />3922-8765-8765</td>
                        <td className="ThemeText2">3922-8765-8765-75648</td>
                        <td className="ThemeText2">34178400000394756387</td>
                        <td className="ThemeText3">در صف بررسی</td>
                        <td><DeleteIcon /><CreateIcon /></td>
                    </tr> */}
                    <tbody>
                    {data.map((user) => (
                        <tr key={user.ID} id={user.ID}>
                            <td className="ThemeText2"><img src={user.CryptoIcon} alt="" />{user.CryptoDetails}</td>
                            <td className="ThemeText2">{user.CryptoName}</td>
                            <td className="ThemeText3">{user.CryptoTotal}</td>
                            <td className="ThemeText2">{user.CryptoToman}</td>
                            <td></td>
                            <td className="ThemeText2">{user.WorldPrice}</td>
                            <td></td>
                            <td></td>
                            <td className="ThemeText3">دپوزیت</td>
                            <td className="ThemeText4">برداشت</td>
                            <td className="ThemeText2">مبادله</td>
                            <td className="ThemeText2"><ExitToAppIcon /></td>
                        </tr>
                        ))}
                    </tbody>
                </table>
                </div>
            </div>
        </div>
    )
}

export default Wallet
