import React,{useState} from 'react'
import ReactCodeInput from 'react-code-input';
//import Material Ui Icons
import TextsmsIcon from '@material-ui/icons/Textsms';
import CloseIcon from '@material-ui/icons/Close';
import CachedIcon from '@material-ui/icons/Cached';
 //import Router
 import {
    Link
} from "react-router-dom";

const AuthenticationPhone = () => {

    const [sendCode , setSendCode] = useState(false);

    return (
        <div className="AuthenticationPhoneWrapper">
            <div className="AuthenticationPhonePanel ThemeBorder ThemeShadow ThemeLayer2">
            <div className="BankAddAccountPanelCloseWrapper"><Link to="/security"><CloseIcon className="BankAddAccountPanelClose"/></Link></div>
                <TextsmsIcon />
                {sendCode ? 
                    <React.Fragment>
                        <span className="AuthenticationTitle1">فعال سازی تصدیق پیامکی</span>
                        <span className="AuthenticationTitle3">لطفا کد ۶ رقمی ارسالی به موبایل خود را وارد کنید</span>
                        <ReactCodeInput type='number' fields={6} />
                        <div className="AuthenticationResend">
                            <CachedIcon />
                            <span className="ThemeText2">ارسال مجدد</span>
                        </div>
                        <div className="AuthenticationPhoneAction" onClick={() => setSendCode(!sendCode)}>
                            <span>فعال سازی</span>
                        </div>
                    </React.Fragment>
                :

                    <React.Fragment>
                        <span className="AuthenticationTitle1">فعال سازی تصدیق پیامکی</span>
                        <span className="AuthenticationTitle2">این پروسه به شما کمک میکند که با ارسال پیامک به تلفن همراهتان ٬ امنیت حساب کاربری شما افزایش یابد.</span>
                        <div className="AuthenticationPhoneAction" onClick={() => setSendCode(!sendCode)}>
                            <span>ارسال پیامک</span>
                        </div>
                    </React.Fragment>
                }
            </div>
        </div>
    )
}

export default AuthenticationPhone
