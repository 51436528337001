import React,{useState} from 'react'
//import Material Ui Icons
import CloseIcon from '@material-ui/icons/Close';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PortraitIcon from '@material-ui/icons/Portrait';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
 //import Router
 import {
    Link
} from "react-router-dom";
//import Material ui Step Component
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

  
  function getSteps() {
    return ['اطلاعات هویتی', 'آپلود مدارک', 'ارسال سلفی با مدارک'];
  }
  
  function getStepTitle(stepIndex) {
    switch (stepIndex) {
      case 0:
        return 'لطغا اطلاعات هویتی خود را طبق کارت ملی تکمیل کنید';
      case 1:
        return 'یکی از اسناد زیر را جهت اپلود تصویر انتخاب کنید';
      case 2:
        return 'لطفا متن زیر را بر روی یک برگه A4 نوشته و به همراه الصاق کارت ملی به آن از خودتان عکس گرفته و پس از امضا و تاریخ ارسال نمایید';
      default:
        return 'به پی پول خوش آمدید';
    }
  }

  function getStepContent(stepIndex,uploadDocumentType,setUploadDocumentType,name,setName,family,setFamily,phoneNumber,setPhoneNumber) {
      
    switch (stepIndex) {
        case 0:
            return (
                <React.Fragment>
                    <div className="AccountVerifyPanelUlWrapper">
                        <ul>
                            <li>
                                <div className="AccountVerifyItemHeader"><span className="ThemeText2">نام</span></div>
                                <div className="AccountVerifyItemBody"><input type="text" name="name" placeholder="نام" className="ThemeBorder" value={name} onChange={event => setName(event.target.value)} required/></div>
                            </li>
                            <li>
                                <div className="AccountVerifyItemHeader"><span className="ThemeText2">نام خانوادگی</span></div>
                                <div className="AccountVerifyItemBody"><input type="text" name="family" placeholder="نام خانوادگی" className="ThemeBorder" value={family} onChange={event => setFamily(event.target.value)} required/></div>
                            </li>
                            <li>
                                <div className="AccountVerifyItemHeader"><span className="ThemeText2">شماره موبایل</span></div>
                                <div className="AccountVerifyItemBody"><input type="tel" name="phone" placeholder="شماره موبایل" className="ThemeBorder" value={phoneNumber} onChange={event => setPhoneNumber(event.target.value)} required/></div>
                            </li>
                        </ul>
                    </div>
                </React.Fragment>
            )
          case 1:
             
            return (
                <React.Fragment>
                    <div className="AccountVerifyUploadDocuments">
                  
                        {uploadDocumentType === 'IdCard' ? 
                        <div className="IdCardUploadWrapper">
                            <ul>
                                <li className="ThemeBorder">
                                    <CloudUploadIcon />
                                    <span className="ThemeText2">برای آپلود تصویر روی کارت ملی کلیک کنید</span>
                                </li>
                                <li className="ThemeBorder">
                                    <CloudUploadIcon />
                                    <span className="ThemeText2">برای آپلود تصویر پشت کارت ملی کلیک کنید</span>
                                </li>
                            </ul>
                        </div> 
                        : (uploadDocumentType === 'PassPort' ? 'Pass port' : (uploadDocumentType === 'GovaahiNameh' ? 'GovaahiNameh' : <ul>
                            <li onClick={() => setUploadDocumentType('IdCard')}>
                                <div className="ThemeBorder"><AssignmentIndIcon /></div>
                                <span>کارت ملی</span>
                            </li>
                            <li onClick={() => setUploadDocumentType('PassPort')}>
                                <div className="ThemeBorder"><PortraitIcon /></div>
                                <span>گذرنامه</span>
                            </li>
                            <li onClick={() => setUploadDocumentType('GovaahiNameh')}>
                                <div className="ThemeBorder"><RecentActorsIcon /></div>
                                <span>گواهی نامه</span>
                            </li>
                        </ul>))}
                    </div>
                </React.Fragment>
            )
          case 2:
            return (
                <React.Fragment>
                    <div className="AccountVerifyUploadQualifyPageWrapper">
                       <div className="AccountVerifyUploadQualifyPageContainer">
                        <div className="AccountVerifyUploadQualifyPage ThemeBorder">
                            <span className="AccountVerifyUploadQualifySmapleText">
    اینجانب................ با کد ملی.............. در مورخه ............. با تلفن همراه ..................   ، ضمن مطالعه و تایید قوانین استفاده از خدمات پی پول، مدارک خود را جهت احراز هویت در پلتفرم سایت پی پول ارسال و اعلام می دارم که حساب کاربری و مدارک خود را به منظور خرید و فروش بیت کوین و سایر ارزهای دیجیتال در اختیار سایر افراد، قرار ندهم و متعهد می شوم که از دریافت وجه خرید ارز دیجیتال برای اشخاص دیگر، خودداری کنم. همچنین اگر خرید هر نوع ارز دیجیتال با وجه سرقتی از جانب بنده باشد، یا در صورت هرگونه تخلف ، مسوولیت آنرا اعم از مالی، حقوقی و قضایی به صورت کامل به عهده می گیرم.  </span><br />
                                <span className="AccountVerifyUploadQualifySmapleText2 ThemeText2">جهت احراز هویت در پلتفرم پی پول <br />الصاق کارت ملی + نام و نام خانوادگی + تاریخ + امضا</span>
                            </div>
                            <div className="AccountVerifyUloadSample">
                                <div className="AccountVerifyUploadQualifySmapleImage"></div>
                                <div className="AccountVerifyUploadQualifyPageSubmit ThemeBorder">
                                        <CloudUploadIcon />
                                        <span className="ThemeText2">برای آپلود تصویر سلفی با کارت ملی کلیک کنید</span>
                                </div> 
                            </div>
                            </div>
                       </div>
                     
                </React.Fragment>
            )
      default:
        return (
            <React.Fragment>
                <div className="AccountVerifyStepFinaly" >
                    <span>تبریک! حساب کاربری پی پول شما فعال شد</span>
                    <span>لطفا وارد سایت شوید و ترید خود را شروع کنید</span>
                </div>
            </React.Fragment>
        );
    }
  }

const AccountVerify = () => {

    const [name , setName] = useState('');
    const [family , setFamily] = useState('');
    const [phoneNumber , setPhoneNumber] = useState('');
    const [uploadDocumentType , setUploadDocumentType] = useState('');
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };


  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


    return (
        <div className="AccountVerifyStepWrapper">
            <div className="AccountVerifyStepPanel ThemeLayer2 ThemeBorder">
            {activeStep === 0 ? <div className="BankAddAccountPanelCloseWrapper"><Link to="/account-verify-start"><CloseIcon className="BankAddAccountPanelClose"/></Link></div>
           : <div className="BankAddAccountPanelCloseWrapper"><ArrowRightAltIcon onClick={handleBack} className="BankAddAccountPanelClose"/></div>}

                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                        ))}
                    </Stepper>

                    <span className="StepHeader ThemeText2">{getStepTitle(activeStep)}</span>

                    <div className="AccountVerifyStepPanelContent">
                        {getStepContent(activeStep,uploadDocumentType,setUploadDocumentType,name,setName,family,setFamily,phoneNumber,setPhoneNumber)}
                    </div>
                    {activeStep === 3 ? <div className='AccountVerifyStepButtonsSubmitLogin' >ورود</div> : <div className={activeStep === 0 ? (name ? (family ? (phoneNumber ? 'AccountVerifyStepButtonsSubmit' : 'AccountVerifyStepButtonsSubmitDisabled') : 'AccountVerifyStepButtonsSubmitDisabled') : 'AccountVerifyStepButtonsSubmitDisabled') : (activeStep === 3 ? 'AccountVerifyStepButtonsSubmitLogin' : 'AccountVerifyStepButtonsSubmit') } onClick={handleNext}>
                            {activeStep === steps.length  ? 'ورود' : 'بعدی'}
                        </div>}
                        <div className="AccountVerifyStepButtons">
                            {activeStep === steps.length ? (
                                <div>
                                    </div>
                                    ) : (
                                    <div>
                                        <div>
                                        {/* <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            className={classes.backButton}
                                        >
                                            قبل
                                        </Button> */}
                                       
                                        </div>
                                    </div>
                                )}
                                </div>
                        </div>
        </div>
    )
}

export default AccountVerify
