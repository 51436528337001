import React from 'react'
//import Material Ui Icons
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import CloseIcon from '@material-ui/icons/Close';
 //import Router
 import {
    Link
} from "react-router-dom";

const BankAddAccount = () => {
    return (
        <div className="BankAddAccountWrapper">
            <div className="BankAddAccountPanel ThemeLayer2 ThemeShadow ThemeBorder">
            <div className="BankAddAccountPanelCloseWrapper"><Link to="/profile"><CloseIcon className="BankAddAccountPanelClose"/></Link></div>
                <AccountBalanceWalletIcon />
                <h1 className="BankAddAccountPanelHeaderText">افزودن حساب بانکی</h1>
                <span className="BankAddAccountPanelHeaderDescription ThemeText2">حساب بانکی شما توسط کارشناسان ما اعتبارسنجی خواهد شد</span>
                <div className="BankAddAccountPanelUlWrapper">
                    <ul>
                        <li>
                            <div className="BankAddAccountItemHeader"><span className="ThemeText2">شماره حساب</span></div>
                            <div className="BankAddAccountItemBody"><input type="text" placeholder="شماره حساب" className="ThemeBorder"/></div>
                        </li>
                        <li>
                            <div className="BankAddAccountItemHeader"><span className="ThemeText2">شماره کارت</span></div>
                            <div className="BankAddAccountItemBody"><input type="text" placeholder="شماره کارت" className="ThemeBorder"/></div>
                        </li>
                        <li>
                            <div className="BankAddAccountItemHeader"><span className="ThemeText2">شماره شبا</span></div>
                            <div className="BankAddAccountItemBody"><div className="ThemeText2 ThemeLayer3"><span className="ThemeText2 ThemeLayer3">IR</span></div><input type="text" placeholder="شماره شبا" className="ThemeBorder"/></div>
                        </li>
                    </ul>
                </div>
                <div className="BankAccountSubmit"><span>افزودن</span></div>
            </div>
        </div>
    )
}

export default BankAddAccount
