import React from 'react'
//import Material Ui Icons
import CloseIcon from '@material-ui/icons/Close';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
//import Router
 import {
    Link
} from "react-router-dom";

const ShowTicket = () => {
    return (
        <div className="ShowTicketWrapper">
            <div className="ShowTicketPanel ThemeLayer2 ThemeShadow ThemeBorder">
                <Link to="/dashboard/support"><CloseIcon className="BankAddAccountPanelClose"/></Link>
                <div className="ShowTicketHeader">
                    <ConfirmationNumberIcon />
                    <span className="ThemeText2">تیکت شما</span>
                </div>
                <div className="ShowTicketPanelUlWrapper">
                    <ul>
                        <li className="ThemeLayer2 ThemeBorder">
                            <div className="ShowTicketPanelLiTitle">
                                <span>دپارتمان</span>
                            </div>
                            <div className="ShowTicketPanelLiDescription">
                                <span className="ThemeText2">پشتیبانی مالی</span>
                            </div>
                        </li>
                        <li className="ThemeLayer2 ThemeBorder">
                            <div className="ShowTicketPanelLiTitle">
                                <span>عنوان</span>
                            </div>
                            <div className="ShowTicketPanelLiDescription">
                                <span className="ThemeText2">عدم ارسال به موقع تتر</span>
                            </div>
                        </li>
                        <li className="ThemeLayer2 ThemeBorder">
                            <div className="ShowTicketPanelLiTitle">
                                <span>توضیحات</span>
                            </div>
                            <div className="ShowTicketPanelLiDescription">
                                <span className="ThemeText2">با سلام ٫ چند روز قبل از سایت شماافدام به خرید تتر کردم ولی هنوز برایم واریز نشده لطفا پیگیری بفرمایید ٬ تا مشکل بنده حل شود</span>
                            </div>
                        </li>
                        <li className="ThemeLayer2 ThemeBorder">
                            <div className="ShowTicketPanelLiTitle">
                                <span>وضعیت</span>
                            </div>
                            <div className="ShowTicketPanelLiDescription">
                                <span className="ThemeText3">در حال پردازش</span>
                            </div>
                        </li>
                        <li className="ThemeLayer2 ThemeBorder">
                            <div className="ShowTicketPanelLiTitle">
                                <span>تاریخ</span>
                            </div>
                            <div className="ShowTicketPanelLiDescription">
                                <span className="ThemeText2">22:12:01 1400/01/22</span>
                            </div>
                        </li>
                        <li className="ThemeLayer2 ThemeBorder">
                            <div className="ShowTicketPanelLiTitle">
                                <span>پاسخ</span>
                            </div>
                            <div className="ShowTicketPanelLiDescription">
                                <span className="ThemeText3">در صف بررسی</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default ShowTicket
