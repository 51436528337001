import React from 'react'
import HeaderButton from '../GlobalComponents/HeaderButton';
import PanelHeader from '../GlobalComponents/PanelHeader';
//import Material Ui Icons
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';

const RefferalList = () => {
    const data = [
        {ID : '1' , FriendsCount : '20' , FriendsTransactions : '120' , YourShare : '20%' , TotalWage : '25,000,000' , YourWage : '23,000,000' , Status : 'در انتظار تسویه'},
        ]

        const data2 = [
            {ID : '1' , NameFamily : 'محمد نوری' , Email : 'Nori@gmail.com' , Phone : '09124839201' , TransactionCount : '20%' , TotalWage : '25,000,000' , Date : '23,000,000'},
            ]

    return (
        <div className="RefferalCodeWrapper">
            <div className="RefferalCode ThemeBorder ThemeShadow ThemeLayer3">
                <PanelHeader Title="دعوت از دوستان (کد ریفرال)" Action1={
                    <HeaderButton Name="لینک و کد ریفرال" LinkAddress="/dashboard/refferal-code" Icon={<PersonAddIcon />} />
                } />
                <div className="TableWrapper">
                <table>
                    <thead>
                        <tr>
                        <th>تعداد دوستان شما</th>
                        <th>تعداد معاملات دوستان شما</th>
                        <th>سهم شما</th>
                        <th>مجموع کارمزد دریافتی شما</th>
                        <th>مجموع کارمزد پرداخت شده به شما</th>
                        <th>وضعیت</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.map((user) => (
                        <tr key={user.ID} id={user.ID}>
                            <td className="ThemeText2">{user.FriendsCount}</td>
                            <td className="ThemeText2">{user.FriendsTransactions}</td>
                            <td className="ThemeText3">{user.YourShare}</td>
                            <td className="ThemeText2">{user.TotalWage}</td>
                            <td className="ThemeText2">{user.YourWage}</td>
                            <td className="ThemeText2">{user.Status}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                </div>
            </div>
            <div className="RefferalCode ThemeBorder ThemeShadow ThemeLayer3">
                <PanelHeader Title="لیست دوستان زیر مجموعه" Action1={
                        <HeaderButton Name="درخواست تصفیه" LinkAddress="/dashboard/purification" Icon={<LocalAtmIcon />} />
                        } />
                <div className="TableWrapper">
                <table>
                    <thead>
                        <tr>
                        <th>آیدی</th>
                        <th>نام و نام خانوادگی</th>
                        <th>ایمیل</th>
                        <th>شماره موبایل</th>
                        <th>تعداد معاملات</th>
                        <th>مجموع کارمزد</th>
                        <th>تاریخ عضویت</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data2.map((user) => (
                        <tr key={user.ID} id={user.ID}>
                            <td className="ThemeText2">{user.ID}</td>
                            <td className="ThemeText2">{user.NameFamily}</td>
                            <td className="ThemeText3">{user.Email}</td>
                            <td className="ThemeText3">{user.Phone}</td>
                            <td className="ThemeText2">{user.TransactionCount}</td>
                            <td className="ThemeText2">{user.TotalWage}</td>
                            <td className="ThemeText2">{user.Date}</td>
                        </tr>
                        ))}
                    </tbody>
                </table>
                </div>
            </div>
        </div>
    )
}

export default RefferalList
