import React from 'react'
import HeaderButton from '../GlobalComponents/HeaderButton';
import HeaderButton2 from '../GlobalComponents/HeaderButton2';
import HeaderButton3 from '../GlobalComponents/HeaderButton3';
import PanelHeader from '../GlobalComponents/PanelHeader';
//import Material Ui Icons
import AssessmentIcon from '@material-ui/icons/Assessment';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const DepositRial = () => {

    const data = [
        {ID : '1' , Market : 'تتر/تومان' , Price : '24,200' , Type : 'IRR' , Link : '23K8X41'  , Status : 'تایید شده' , Date : '1400/01/23'},
        {ID : '2' , Market : 'تتر/تومان' , Price : '32,000' , Type : 'TRC20' , Link : '23K8X41'  , Status : 'تایید شده' , Date : '1400/01/23'},
    ]

    return (
        <div className="DepositRialWrapper">
            <div className="DepositRialPanel ThemeLayer2 ThemeBorder ThemeShadow ">
                <PanelHeader Title="واریز ریالی (تومان)" Action1={
                            <HeaderButton Name="تاریخچه واریز ها" LinkAddress="/dashboard/deposit-history" Icon={<AssessmentIcon />} />
                } Action2={
                    <HeaderButton Name="راهنمای تصویری واریز وجه" LinkAddress="/dashboard/new-ticket" Icon={<PlayCircleOutlineIcon />} />
                } />
                
                <div className="DepostiRialPanelTotal ThemeBorderBottom">
                    <div className="DepostiRialPanelTotalActions">
                    <HeaderButton2 Name="بازگشت به کیف پول ها" LinkAddress="/dashboard/wallet" Icon={<AccountBalanceWalletIcon />} />
                    <HeaderButton2 Name="افزودن کارت بانکی" LinkAddress="/dashboard/bank-add" Icon={<CreditCardIcon />} />
                    </div>
                    <div className="DepostiRialPanelTotalPrice">
                        <img src="./Logo/iran.png" alt="" />
                        <span className="ThemeText2">موجودی :</span>
                        <input type="text" className="ThemeLayer2 ThemeShadow ThemeBorder ThemeText2" value="192,000,000   تومان " />
                    </div>
                </div>

                <div className="DepositRialPanelDescription">
                    <div className="DepostiPanelDescriptionHeader">
                        <span className="ThemeText2">واریز از طریق درگاه شاپرک (شارژ کیف پول)</span>
                    </div>
                    <ul>
                        <li><span>لطفا٬ قبل از واریز وجه از درگاه شاپرک ٬ توضیحات زیر را با دقت مطالعه نمایید٬ مسئولیت هرگونه اشتباهی در اثر بی دقتی ر واریز وجه ٬ بر عهده کاربر میباشد.</span></li>
                        <li><CheckBoxIcon /><span>لطفا هنگام واریز و انتقال به درگاه بانکی ٬ دقت بسیار نمایید و از پرداخت وجه در صفحه سامانه شاپرک اطمینان حاصل کنید</span></li>
                        <li><CheckBoxIcon /><span>به مبلغ واریزی در صفحه درگاه دقت کنید که مبلغ واریزیِ نمایش داده شده ٬ درست باشد</span></li>
                        <li><CheckBoxIcon /><span>حداکثر مجموع مبلغ واریزی در 24 ساعت معادل : 20,000,000 تومان میباشد</span></li>
                        <li><CheckBoxIcon /><span>وضعیت تراکنش واریزی را از قسمت تاریخچه واریزی ها پیگیری نمایید</span></li>
                        <li><CheckBoxIcon /><span>حداقل مبلغ واریزی برای معامله یا خرید و فروش ارز در سایت پِی پول٬ 200,000 تومان است</span></li>
                        <li><span className="ThemeText4">دقت نمایید٬ برای واریز وجه٬ فقط از کارتها و حسابهای بانکی تایید شده موجود در پروفایلتان استفاده کنید٬ در غیر این صورت واریز وجه نا موفق خواهد شد</span></li>
                    </ul>
                </div>

                <div className="DepositRialPanelActions">
                    <div className="DepositRialPanelActionsItem">
                        <div className="DepositRialPanelActionsItemHeader">
                            <span>کارت بانکی</span>
                        </div>
                        <div className="DepositRialPanelActionsItemBody">
                            <select className="DepositRialPanelSelectCard ThemeBorder ThemeLayer2 ThemeText2">
                                <option value="defult">انتخاب کارت بانکی برای واریز</option>
                                <option value="saab">صادرات (6037-12345)</option>
                                <option value="opel">ملی (7564-32984)</option>
                                <option value="audi">نوین (4654-23049)</option>
                            </select>
                        </div>
                    </div>

                    <div className="DepositRialPanelActionsItem">
                        <div className="DepositRialPanelActionsItemHeader">
                            <span>مبلغ واریزی به تومان</span>
                        </div>
                        <div className="DepositRialPanelActionsItemBody">
                            <input type="text" className="ThemeLayer ThemeBorder" placeholder="لطفا مبلغ را به تومان وارد کنید" />
                        </div>
                    </div>

                    <HeaderButton3 Name="انتقال به درگاه پرداخت" LinkAddress="#     "  />
                    

                </div>

            </div>

            <div className="Orders ThemeBorder ThemeShadow ThemeLayer3">
            <PanelHeader Title="تاریخچه واریز ها" Action1={
                        <HeaderButton Name="کیف پول های من" LinkAddress="/dashboard/wallet" Icon={<AccountBalanceWalletIcon />} />
                        } />
                <div className="TableWrapper">
                <table>
                    <thead>
                        <tr>
                            <th>توکن (ارز)</th>
                            <th>مقدار</th>
                            <th>نوع</th>
                            <th>شماره پیگیری/لینک تراکنش</th>
                            <th>وضعیت</th>
                            <th>تاریخ</th>
                        </tr>
                    </thead>
                    {/* <tr>
                        <td className="ThemeText2"><CreditCardIcon />3922-8765-8765</td>
                        <td className="ThemeText2">3922-8765-8765-75648</td>
                        <td className="ThemeText2">34178400000394756387</td>
                        <td className="ThemeText3">در صف بررسی</td>
                        <td><DeleteIcon /><CreateIcon /></td>
                    </tr>
                    <tr>
                        <td className="ThemeText2"><CreditCardIcon />3922-8765-8765</td>
                        <td className="ThemeText2">3922-8765-8765-75648</td>
                        <td className="ThemeText2">34178400000394756387</td>
                        <td className="ThemeText3">در صف بررسی</td>
                        <td><DeleteIcon /><CreateIcon /></td>
                    </tr> */}
                    <tbody>
                    {data.map((user) => (
                        <tr key={user.ID} id={user.ID}>
                            <td className="ThemeText2">{user.Market}</td>
                            <td className="ThemeText2">{user.Price}</td>
                            <td className="ThemeText2">{user.Type}</td>
                            <td className="ThemeText2">{user.Link}</td>
                            <td className="ThemeText3">{user.Status}</td>
                            <td className="ThemeText2">{user.Date}</td>
                        </tr>
                        ))}
                    </tbody>
                </table>
                </div>
            </div>

            
        </div>
    )
}

export default DepositRial
