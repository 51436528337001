import React from 'react'
    //import Router
    import {
        Link
    } from "react-router-dom";
//import Material Ui Icons
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CreateIcon from '@material-ui/icons/Create';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import MailIcon from '@material-ui/icons/Mail';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DeleteIcon from '@material-ui/icons/Delete';

const Profile = () => {

    const data = [
        {ID : '1' , AccountNumber : '3922-8765-8765' , CardNumber : '3922-8765-8765-75648' , ShabaNumber : 34178400000394756387 , Status : 'در صف بررسی'},
        {ID : '2' , AccountNumber : '3922-8765-8765' , CardNumber : '3922-8765-8765-75648' , ShabaNumber : 34178400000394756387 , Status : 'در صف بررسی'},
    ]
    

    return (
        <div className="ProfileWrapper">
            <div className="AccountInformationWrapper ThemeLayer2 ThemeShadow ThemeBorder">
                <div className="AccountInformationWrapperHeader">
                    <span className="AccountInformationChange ThemeBorder"><span><Link to="/dashboard/profile-edit">ویرایش پروفایل</Link></span></span>
                    <span className="AccountVerificationStatusWrapper"> وضعیت <span className="AccountVerificationStatus ThemeLayer3"> تایید شده سطح ۱</span></span>
                    <span className="AccountInformationHeaderTitle ThemeText2">پروفایل (اطلاعات حساب کاربری)</span>
                </div>
                <ul>
                    <li>
                        <div className="InformationType">
                            <AccountCircleIcon />
                            <span className="ThemeText2">نام</span>
                        </div>
                        <div className="InformationData">
                            <span className="ThemeText2">امیر ارسلان</span>
                        </div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div className="InformationActions">
                            <CreateIcon />
                            <CheckCircleOutlineIcon />
                        </div>
                    </li>
                    <li>
                        <div className="InformationType">
                            <AccountBoxIcon />
                            <span className="ThemeText2">نام خانوادگی</span>
                        </div>
                        <div className="InformationData">
                            <span className="ThemeText2">محمدزاده</span>
                        </div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div className="InformationActions">
                            <CreateIcon />
                            <CheckCircleOutlineIcon />
                        </div>
                    </li>
                    <li>
                        <div className="InformationType">
                            <AssignmentIndIcon />
                            <span className="ThemeText2">کدملی</span>
                        </div>
                        <div className="InformationData">
                            <span className="ThemeText2">123456789</span>
                        </div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div className="InformationActions">
                            <CreateIcon />
                            <CheckCircleOutlineIcon />
                        </div>
                    </li>
                    <li>
                        <div className="InformationType">
                            <PhoneAndroidIcon />
                            <span className="ThemeText2">شماره موبایل</span>
                        </div>
                        <div className="InformationData">
                            <span className="ThemeText2">09125438970</span>
                        </div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div className="InformationActions">
                            <CreateIcon />
                            <CheckCircleOutlineIcon />
                        </div>
                    </li>
                    <li>
                        <div className="InformationType">
                            <MailIcon />
                            <span className="ThemeText2">ایمیل</span>
                        </div>
                        <div className="InformationData">
                            <span className="ThemeText2">amirarsalam@gmail.com</span>
                        </div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div className="InformationActions">
                            <CreateIcon />
                            <CheckCircleOutlineIcon />
                        </div>
                    </li>
                    <li>
                        <div className="InformationType">
                            <EmojiEventsIcon />
                            <span className="ThemeText2">سطج کاربری تایید شده</span>
                        </div>
                        <div className="InformationData">
                            <span className="ThemeText3">P1</span>
                        </div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div className="InformationActions">
                            <CreateIcon />
                            <span className="ThemeText3">ارتقای سطح کاربری</span>
                        </div>
                    </li>
                </ul>
            </div>


            <div className="BankInformationWrapper ThemeLayer2 ThemeBorder">
                <div className="BankInformationWrapperHeader">
                    <span className="‌BankInformationChange ThemeBorder"><span><Link to="/dashboard/bank-add">افزودن کارت و حساب بانکی</Link></span></span>
                    <span className="BankInformationHeaderTitle ThemeText2">اطلاعات حساب های بانکی</span>
                </div>
                <table>
                    <thead>
                        <tr>
                        <th>شماره حساب</th>
                        <th>شماره کارت</th>
                        <th>شماره شبا</th>
                        <th>وضعیت</th>
                        <th>عملیات</th>
                    </tr>
                    </thead>
                    {/* <tr>
                        <td className="ThemeText2"><CreditCardIcon />3922-8765-8765</td>
                        <td className="ThemeText2">3922-8765-8765-75648</td>
                        <td className="ThemeText2">34178400000394756387</td>
                        <td className="ThemeText3">در صف بررسی</td>
                        <td><DeleteIcon /><CreateIcon /></td>
                    </tr>
                    <tr>
                        <td className="ThemeText2"><CreditCardIcon />3922-8765-8765</td>
                        <td className="ThemeText2">3922-8765-8765-75648</td>
                        <td className="ThemeText2">34178400000394756387</td>
                        <td className="ThemeText3">در صف بررسی</td>
                        <td><DeleteIcon /><CreateIcon /></td>
                    </tr> */}
                    <tbody>
                    {data.map((user) => (
                        <tr key={user.ID} id={user.ID}>
                            <td className="ThemeText2"><CreditCardIcon />{user.AccountNumber}</td>
                            <td className="ThemeText2">{user.CardNumber}</td>
                            <td className="ThemeText2">{user.ShabaNumber}</td>
                            <td className="ThemeText3">{user.Status}</td>
                            <td><DeleteIcon /><CreateIcon /></td>
                        </tr>
                        ))}
                    </tbody> 
                </table>
            </div>
        </div>
    )
}

export default Profile
