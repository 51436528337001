import React from 'react'
//import Material Ui Icons
import LockOpenIcon from '@material-ui/icons/LockOpen';
import CloseIcon from '@material-ui/icons/Close';
 //import Router
 import {
    Link
} from "react-router-dom";

const ChangePassword = () => {
    return (
        <div className="ChangePasswordWrapper">
            <div className="ChangePasswordPanel ThemeShadow ThemeLayer2 ThemeBorder">
            <div className="ChangePasswordPanelCloseWrapper"><Link to="/security"><CloseIcon className="ChangePasswordPanelClose"/></Link></div>
                <LockOpenIcon />
                <span className="ChangePasswordPanelHeaderText">تغییر رمز ورود</span>
                <div className="ChangePasswordPanelUlWrapper">
                    <ul>
                        <li>
                            <div className="ChangePasswordItemHeader"><span className="ThemeText2">رمز ورود فعلی</span></div>
                            <div className="ChangePasswordItemBody"><input type="text" placeholder="رمز ورود فعلی" className="ThemeBorder"/></div>
                        </li>
                        <li>
                            <div className="ChangePasswordItemHeader"><span className="ThemeText2">روز ورود جدید</span></div>
                            <div className="ChangePasswordItemBody"><input type="text" placeholder="رمز ورود جدید" className="ThemeBorder"/></div>
                        </li>
                        <li>
                            <div className="ChangePasswordItemHeader"><span className="ThemeText2">تکرار رمز ورود جدید</span></div>
                            <div className="ChangePasswordItemBody"><input type="text" placeholder="تکرار رمز ورود جدید" className="ThemeBorder"/></div>
                        </li>
                    </ul>
                </div>
                <div className="BankAccountSubmit"><span>تغییر رمز ورود</span></div>
            </div>
        </div>
    )
}

export default ChangePassword
