import React from 'react'
import HeaderButton from '../GlobalComponents/HeaderButton';
import PanelHeader from '../GlobalComponents/PanelHeader';
//import Material Ui Icons
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';

//import Router
 import {
    Link
} from "react-router-dom";

const Support = () => {

    const data = [
        {ID : '1' , Subject : 'عدم ارسال به موقع تتر' , Departman : 'پشتیبان مالی' , Status : 'پاسخ داده شد' , Date : '1400/02/30' , Update : '1400/03/13' },
        {ID : '2' , Subject : 'عدم ارسال به موقع تتر' , Departman : 'پشتیبان مالی' , Status : 'پاسخ داده شد' , Date : '1400/02/30' , Update : '1400/03/13' },
    ]

    return (
        <div className="SupportWrapper">
            <div className="SupportTickets ThemeBorder ThemeShadow ThemeLayer3">
                <PanelHeader Title="پشتیبانی (تیکت ها)" Action1={
                        <HeaderButton Name="ثبت تیکت" LinkAddress="/dashboard/new-ticket" Icon={<AddToPhotosIcon />} />
                        } />
                <div className="TableWrapper">
                    <table>
                        <thead>
                            <tr>
                            <th>عنوان</th>
                            <th>دپارتمان</th>
                            <th>وضعیت</th>
                            <th>تاریخ ثبت</th>
                            <th>تاریخ بروزرسانی</th>
                            <th></th>
                        </tr>
                        </thead>
                        {/* <tr>
                            <td className="ThemeText2"><CreditCardIcon />3922-8765-8765</td>
                            <td className="ThemeText2">3922-8765-8765-75648</td>
                            <td className="ThemeText2">34178400000394756387</td>
                            <td className="ThemeText3">در صف بررسی</td>
                            <td><DeleteIcon /><CreateIcon /></td>
                        </tr>
                        <tr>
                            <td className="ThemeText2"><CreditCardIcon />3922-8765-8765</td>
                            <td className="ThemeText2">3922-8765-8765-75648</td>
                            <td className="ThemeText2">34178400000394756387</td>
                            <td className="ThemeText3">در صف بررسی</td>
                            <td><DeleteIcon /><CreateIcon /></td>
                        </tr> */}
                        <tbody>
                        {data.map((user) => (
                            <tr key={user.ID} id={user.ID}>
                                <td className="ThemeText2">{user.Subject}</td>
                                <td className="ThemeText2">{user.Departman}</td>
                                <td className="ThemeText3">{user.Status}</td>
                                <td className="ThemeText2">{user.Date}</td>
                                <td className="ThemeText2">{user.Update}</td>
                                <td><span className="ThemeText4"><Link to="/dashboard/show-ticket">مشاهده</Link></span><span className="ThemeText3"><Link to="/dashboard/reply-ticket">پاسخ</Link></span></td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Support
