import React from 'react'
//import Material Ui Icons
import CloseIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
//import Router
 import {
    Link
} from "react-router-dom";

const ReplyTicket = () => {
    return (
        <div className="ReplyTicketWrapper">
            <div className="ReplyTicketPanel ThemeLayer2 ThemeShadow ThemeBorder">
                <Link to="/dashboard/support"><CloseIcon className="BankAddAccountPanelClose"/></Link>
                <div className="ReplyTicketHeader">
                    <span className="ThemeText2">پاسخ</span>
                </div>
                <div className="ReplyTicketPanelUlWrapper">
                    <ul>
                        <li>
                            <div className="ReplyTicketItemHeader"><span className="ThemeText2">توضیحات</span></div>
                            <div className="ReplyTicketItemBody"><textarea placeholder="توضیحات" className="ThemeBorder" rows="8"></textarea></div>
                        </li>
                        <li>
                            <div className="ReplyTicketFileUpload ThemeBorder">
                                <CloudUploadIcon />
                                <span className="ThemeText2">برای آپلود تصویر ضمیمه کلیک کنید</span>
                            </div> 
                        </li>
                    </ul>
                </div>

                <div className="ReplyTicketPanelSubmit ThemeBorder">
                    <span>ارسال</span>
                </div>
            </div>
        </div>
    )
}

export default ReplyTicket
