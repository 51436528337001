import React from 'react'

import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import LockIcon from '@material-ui/icons/Lock';

const Login = () => {
    return (
        <div className="LoginWrapper">
            <div className="LoginPanelInner ThemeLayer2 ThemeBorder ThemeShadow">
                <div className="LoginPanelInnerHeader">
                    <img src="https://www.paypalobjects.com/webstatic/icon/pp258.png" alt="" />
                    <span className="LoginPanelLogoText">Pay<span className="LoginPanelLogoText LoginPanelLogoText2">poool</span></span>
                    <span className="LoginPanelDescription">پلتفرم خرید و فروش ارز دیجیتال</span>
                </div>
                <div className="LoginPanelInnerDescription">
                    <div className="LoginPanelInnerDescriptionHeader">
                        <span>ورود</span>
                    </div>
                    <div className="LoginPanelInnerDescriptionBody">
                        <span className="ThemeText2">مطمئن هستید وارد این سایت میشوید</span>
                        <span>https://www.paypoool.com</span>
                    </div>
                </div>
                <ul className="LoginContainerBodyUl">
                    <li><PhoneAndroidIcon /><input type="text" placeholder="ایمیل"/></li>
                    <li><LockIcon /><input type="text" placeholder="رمز عبور"/></li>
                </ul>
                <div className="SignInContainerChekckOut">
                        <input type="checkbox"/>
                        <span>مرا به خاطر بسپار </span>
                </div>
                <div className="SignInButton">
                    <span>ورود</span>
                </div>
                <div className="SignInOtherActions">
                    <span>ثبت نام</span>
                    <span>فراموشی رمز عبور</span>
                </div>
                <div className="SignInGoogleButton ThemeShadow"><span>Sign in with Google</span><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/1200px-Google_%22G%22_Logo.svg.png" alt="" /></div>
                </div>
        </div>
    )
}

export default Login
