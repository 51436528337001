import React from 'react'

const IndexPgae = () => {
    return (
        <div>
            Index Page
        </div>
    )
}

export default IndexPgae
