import React from 'react'
//import Material Ui Icons
import CloseIcon from '@material-ui/icons/Close';
import FileCopyIcon from '@material-ui/icons/FileCopy';
 //import Router
 import {
    Link
} from "react-router-dom";

const AuthenticationApp = () => {
    return (
        <div className="AuthenticationAppWrapper">
            <div className="AuthenticationAppPanel ThemeBorder ThemeShadow ThemeLayer2">
                <div className="BankAddAccountPanelCloseWrapper"><Link to="/security"><CloseIcon className="BankAddAccountPanelClose"/></Link></div>
                <img src="https://www.paypalobjects.com/webstatic/icon/pp258.png" alt="pay pool authenticator" />
                <span className="AuthenticationPhoneTitle1">فعال سازی تصدیق دوعاملی</span>
                <ul>
                    <li>
                        <div className="AuthenticatorAppLiNumber"><span>1</span></div>
                        <span className="AuthenticatorAppLiText">اپلیکیشن Google Authenticator را روی موبایل خود نصب کنید</span>
                    </li>
                    <li>
                        <div className="AuthenticatorAppLiNumber"><span>2</span></div>
                        <span className="AuthenticatorAppLiText">کد QR را با اپلیکیشن خود اسکن کنید</span>
                    </li>
                    <li>
                        <div className="AuthenticatorAppLiNumber"><span>3</span></div>
                        <span className="AuthenticatorAppLiText">لطفا کد امنیتی زیر را روی برگه ای یادداشت کرده یا پرینت بگیرید و در جای امنی نگهداری کنید</span>
                    </li>
                </ul>
                <div className="AuthenticatorPhoneQrCode ThemeBorder ThemeLayer2">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/QR_code_for_mobile_English_Wikipedia.svg/1200px-QR_code_for_mobile_English_Wikipedia.svg.png" alt="" />
                </div>
                <div className="AuthenticatorAppItemBody">
                    <div className="ThemeText2 ThemeLayer3"><FileCopyIcon /></div>
                    <input type="text" value="KLJHSDKJLFHNVJKFLDVHSDDKJH" className="ThemeBorder"/>
                </div>

                <div className="AuthenticationPanelSubmit">
                    <span>فعال سازی</span>
                </div>
                        
            </div>
        </div>
    )
}

export default AuthenticationApp
