import React , {useState} from 'react'  

const Notification = ({MainText , SubText , NotifUl}) => {

    const [notification, setNotification] = useState(true);

    return (
       <React.Fragment>
            { notification ? 
                <div className="NotificationWrapper ThemeShadow ThemeLayer3 ThemeBorder">
                    <div className="NotificationClose" onClick={() => setNotification(!notification)}>
                        <span>متوجه شدم</span>
                    </div>
                    <span className="ThemeText1"><b className="ThemeText2">{SubText} </b>  {MainText} </span>
                        
                </div> 
            : null }
       </React.Fragment>
    )
}

export default Notification
