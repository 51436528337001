import React from 'react'

const Icon = () => {
    return (
        <div>
            <img src="./src/Home2.svg" alt="" />
        </div>
    )
}

export default Icon
